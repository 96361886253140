import { loggerError } from '@cencosud/puntos-core/src/utils/logger';
var init = function init() {
  try {
    var js = document.createElement('script');
    js.type = 'text/javascript';
    js.async = 1;
    js.src = 'https://go.botmaker.com/rest/webchat/p/FTO7ANFMMN/init.js';
    document.body.appendChild(js);
  } catch (e) {
    loggerError('CHAT');
  }
};
export { init };