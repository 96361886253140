export default (function () {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 0
    },
    title: {
      marginTop: 0,
      marginBottom: 10,
      textAlign: 'center',
      color: '#873695',
      fontWeight: 'normal',
      fontFamily: 'Ubuntu'
    },
    recaptcha: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 30,
      alignItems: 'center',
      marginBottom: 25
    },
    submit: {
      marginBottom: 90,
      marginRight: 0,
      marginLeft: 0,
      width: '100%',
      maxWidth: 500
    },
    subtitle: {
      alignSelf: 'start',
      fontSize: '1.8rem'
    },
    info: {
      text: {
        textAlign: 'center',
        fontSize: '2rem',
        width: '100%'
      }
    },
    modal: {
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 500,
        width: '95%',
        borderRadius: 5,
        textAlign: 'center',
        padding: '10px 15px',
        inset: 'unset'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.7)',
        zIndex: 1050,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  };
});