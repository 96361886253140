import get from 'lodash/get';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { createCatalogoCategoriaEvent, createTiendaEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { showSecondaryProgress, hideSecondaryProgressBar, showPrimaryProgress, hidePrimaryProgressBar } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { GET_EXCHANGE_PRODUCT, GET_EXCHANGE_PRODUCT_FAIL, GET_EXCHANGE_PRODUCT_SUCCESS, setExchangeProduct, getExchangeProductFail, getExchangeProductSuccess, appendExchangeProduct, GET_APPEND_EXCHANGE_PRODUCT, APPEND_EXCHANGE_PRODUCT_FAIL, APPEND_EXCHANGE_PRODUCT_SUCCESS, appendExchangeProductSuccess, appendExchangeProductFail, SET_EXCHANGE_PRODUCT, APPEND_EXCHANGE_PRODUCT } from './exchangeProduct.actions';
import { contentV3Endpoints } from '../api/endpoints';
import history from '../router/history';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import { flatten } from 'lodash/fp';
import ProductExchangeToProductOnlineBinder from '@cencosud/puntos-core/src/features/binder/ProductExchangeToProductOnlineBinder';
import ProductExchangeToStoreProductBinder from '@cencosud/puntos-core/src/features/binder/ProductExchangeToStoreProductBinder';
var registerListEvent = function registerListEvent(products, store, category, hasSubcategory) {
  var storeProducts = [];
  if (store) storeProducts = products === null || products === void 0 ? void 0 : products.map(function (product) {
    return ProductExchangeToStoreProductBinder(product);
  });
  registerEvent(store ? createTiendaEvent({
    nombreTienda: store,
    productos: storeProducts
  }) : createCatalogoCategoriaEvent({
    categoria: hasSubcategory ? get(category, 'nombreCategoria') : get(category, 'nombre'),
    subcategoria: hasSubcategory ? get(category, 'nombre') : undefined,
    productos: products.map(function (product) {
      return ProductExchangeToProductOnlineBinder(product);
    })
  }));
};
export var getExchangeProductFlow = function getExchangeProductFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_EXCHANGE_PRODUCT) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        page = _action$payload.page,
        size = _action$payload.size,
        from = _action$payload.from,
        to = _action$payload.to,
        exchangeType = _action$payload.exchangeType,
        category = _action$payload.category,
        store = _action$payload.store,
        asc = _action$payload.asc,
        query = _action$payload.query;
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.exchangeProducts.getAll({
        page: page,
        size: size,
        from: from,
        to: to,
        exchangeType: exchangeType,
        category: category,
        store: store,
        asc: asc,
        query: query
      }), getExchangeProductSuccess, getExchangeProductFail)), dispatch(showPrimaryProgress())]).then(function (actions) {
        var _flatten$find = flatten(actions).find(function (_ref2) {
            var type = _ref2.type;
            return type === SET_EXCHANGE_PRODUCT;
          }),
          products = _flatten$find.payload.products;
        var hasSubcategory = !!get(category, 'nombreCategoria');
        var path = history.location.pathname;
        if (path !== '/' && !query) {
          registerListEvent(products, store, category, hasSubcategory);
        }
        return actions;
      }).then(function () {
        return dispatch(hidePrimaryProgressBar());
      });
    };
  };
};
export var processGetExchangeProduct = function processGetExchangeProduct(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_EXCHANGE_PRODUCT_SUCCESS:
          {
            var catalogo = payload.payload;
            return Promise.all([dispatch(setExchangeProduct(catalogo.items, catalogo.total))]);
          }
        case GET_EXCHANGE_PRODUCT_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener catalogo de productos (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return Promise.all([nextResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getAppendExchangeProductFlow = function getAppendExchangeProductFlow(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_APPEND_EXCHANGE_PRODUCT) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        page = _action$payload2.page,
        size = _action$payload2.size,
        from = _action$payload2.from,
        to = _action$payload2.to,
        exchangeType = _action$payload2.exchangeType,
        category = _action$payload2.category,
        store = _action$payload2.store,
        asc = _action$payload2.asc,
        query = _action$payload2.query;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(contentV3Endpoints.exchangeProducts.getAll({
        page: page,
        size: size,
        from: from,
        to: to,
        exchangeType: exchangeType,
        category: category,
        store: store,
        asc: asc,
        query: query
      }), appendExchangeProductSuccess, appendExchangeProductFail))]).then(function (actions) {
        var _actions$flat$find = actions.flat().find(function (_ref5) {
            var type = _ref5.type;
            return type === APPEND_EXCHANGE_PRODUCT;
          }),
          products = _actions$flat$find.payload;
        var path = history.location.pathname;
        if (path !== '/' && !query) {
          registerListEvent(products.products, store, category, undefined);
        }
        return actions;
      });
    };
  };
};
export var processAppendExchangeProduct = function processAppendExchangeProduct(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== APPEND_EXCHANGE_PRODUCT_SUCCESS && type !== APPEND_EXCHANGE_PRODUCT_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case APPEND_EXCHANGE_PRODUCT_SUCCESS:
          {
            var catalogo = payload.payload;
            return Promise.all([progressResponse, dispatch(appendExchangeProduct(catalogo.items, catalogo.skip, catalogo.total))]);
          }
        case APPEND_EXCHANGE_PRODUCT_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener catalogo de productos (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, !(payload instanceof AuthenticationError) && dispatch(pushRoute('/error'))]);
          }
        default:
          return [];
      }
    };
  };
};
export default [getExchangeProductFlow, processGetExchangeProduct, getAppendExchangeProductFlow, processAppendExchangeProduct];