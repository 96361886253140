import methods from './httpMethods';
import apiEndpointCreators from './apiEndpointCreators';
var createEndpoint = apiEndpointCreators.OfertasEndpoint;

/* const communUriByToken = '/private/promotions';
const communUriByHash = '/public/promotions'; */
/* const communUriByToken = '/private/promotions';
const communUriByHash = '/public/promotions'; */

export default {
  // --------Descuentos-------
  getUserPrime: function getUserPrime(baseCode) {
    return createEndpoint({
      method: methods.get,
      uri: '/promociones/promotions/user/prime/{baseCode}',
      uriParams: {
        baseCode: baseCode
      }
    });
  },
  activateDiscountpartnerByRut: function activateDiscountpartnerByRut(id, rut) {
    return createEndpoint({
      method: methods.put,
      /*  uri: `${communUriByToken}/couponing/{id}/`, */
      uri: '/promociones/promotions/partner/couponing/{id}',
      uriParams: {
        id: id,
        rut: rut
      }
    });
  },
  primeUserTriesToVerify: function primeUserTriesToVerify(id, baseCode, couponId) {
    return createEndpoint({
      method: methods.put,
      /*  uri: `${communUriByToken}/couponing/{id}/`, */

      uri: '/promociones/promotions/user/prime/verify/{id}',
      uriParams: {
        id: id,
        baseCode: baseCode,
        couponId: couponId
      }
    });
  },
  getDiscountByToken: function getDiscountByToken() {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByToken}/couponing/search/`, */
      uri: '/promotions/couponing/search/'
    });
  },
  getDiscountByHash: function getDiscountByHash(hash) {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByHash}/hash/couponing`, */
      uri: '/promociones/promotions/hash/couponing',
      uriParams: {
        hash: hash
      }
    });
  },
  activateDiscountByToken: function activateDiscountByToken(id) {
    return createEndpoint({
      method: methods.put,
      /*  uri: `${communUriByToken}/couponing/{id}/`, */
      uri: '/promotions/couponing/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  activateAllDiscountByToken: function activateAllDiscountByToken() {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByToken}/couponing/`, */
      uri: '/promotions/couponing/'
    });
  },
  discardDiscountByToken: function discardDiscountByToken(id) {
    return createEndpoint({
      method: methods.delete,
      /* uri: `${communUriByToken}/couponing/{id}/`, */
      uri: '/promotions/couponing/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  discardDiscountByHash: function discardDiscountByHash(id, hash) {
    return createEndpoint({
      method: methods.delete,
      /* uri: `${communUriByHash}/couponing/hash/{id}/`, */
      uri: '/promociones/promotions/couponing/hash/{id}/',
      uriParams: {
        id: id,
        hash: hash
      }
    });
  },
  activateDiscountByHash: function activateDiscountByHash(id, hash) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/couponing/{id}/`, */
      uri: '/promociones/promotions/hash/couponing/{id}/',
      uriParams: {
        id: id,
        hash: hash
      }
    });
  },
  activateAllDiscountByHash: function activateAllDiscountByHash(hash) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/couponing/`, */
      uri: '/promociones/promotions/hash/couponing/',
      uriParams: {
        hash: hash
      }
    });
  },
  //-----------
  getCouponingByToken: function getCouponingByToken() {
    return createEndpoint({
      method: methods.post,
      /*  uri: `${communUriByToken}/challenge/search/`, */
      uri: '/promotions/challenge/search/'
    });
  },
  getCouponingByHash: function getCouponingByHash() {
    return /* deprecate */createEndpoint({
      method: methods.post,
      uri: '/promotions/challenge/search/'
    });
  },
  getByToken: function getByToken() {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByToken}/quarter/search/`, */
      uri: '/promotions/quarter/search/'
    });
  },
  getChallengeByToken: function getChallengeByToken() {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByToken}/challenge/search/`, */
      uri: '/promotions/challenge/search/'
    });
  },
  getByHash: function getByHash() {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByHash}/hash`, */
      uri: '/promociones/promotions/hash'
    });
  },
  getChallengeByHash: function getChallengeByHash(hash) {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByHash}/hash/challenge`, */
      uri: '/promociones/promotions/hash/challenge',
      uriParams: {
        hash: hash
      }
    });
  },
  getWonByToken: function getWonByToken() {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByToken}/won/`, */
      uri: '/promotions/won/'
    });
  },
  getWonByHash: function getWonByHash(hash) {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByHash}/hash/won/`, */
      uri: '/promociones/promotions/hash/won/',
      uriParams: {
        hash: hash
      }
    });
  },
  activateCouponByToken: function activateCouponByToken(id) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByToken}/quarter/{id}/`, */
      uri: '/promotions/quarter/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  activateAllCouponByToken: function activateAllCouponByToken() {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByToken}/quarter/`, */
      uri: '/promotions/quarter/'
    });
  },
  activateChallengeByToken: function activateChallengeByToken(id) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByToken}/challenge/{id}/`, */
      uri: '/promotions/challenge/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  activateAllChallengeByToken: function activateAllChallengeByToken() {
    return createEndpoint({
      method: methods.put,
      /*  uri: `${communUriByToken}/challenge/`, */
      uri: '/promotions/challenge/'
    });
  },
  activateCouponByHash: function activateCouponByHash(id) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/{id}`, */
      uri: '/promociones/promotions/hash/{id}',
      uriParams: {
        id: id
      }
    });
  },
  activateAllCouponByHash: function activateAllCouponByHash() {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/`, */
      uri: '/promociones/promotions/hash/'
    });
  },
  activateChallengeByHash: function activateChallengeByHash(id) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/challenge/{id}`, */
      uri: '/promociones/promotions/hash/challenge/{id}',
      uriParams: {
        id: id
      }
    });
  },
  activateAllChallengeByHash: function activateAllChallengeByHash(hash) {
    return createEndpoint({
      method: methods.put,
      /* uri: `${communUriByHash}/hash/challenge/`, */
      uri: '/promociones/promotions/hash/challenge/',
      uriParams: {
        hash: hash
      }
    });
  },
  discardCouponByToken: function discardCouponByToken(id) {
    return createEndpoint({
      method: methods.delete,
      /*   uri: `${communUriByToken}/quarter/{id}/`, */
      uri: '/promotions/quarter/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  discardCouponByHash: function discardCouponByHash(id) {
    return createEndpoint({
      method: methods.delete,
      /* uri: `${communUriByHash}/hash/{id}`, */
      uri: '/promociones/promotions/hash/{id}',
      uriParams: {
        id: id
      }
    });
  },
  downloadCouponImageByToken: function downloadCouponImageByToken(id) {
    return createEndpoint({
      method: methods.get,
      uri: '/promociones/resource/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  downloadMultiCouponImageByToken: function downloadMultiCouponImageByToken() {
    return createEndpoint({
      method: methods.get,
      uri: '/promociones/resource'
    });
  },
  downloadCouponImageByHash: function downloadCouponImageByHash(hash, id) {
    return createEndpoint({
      method: methods.post,
      /*  uri: `${communUriByHash}/hash/resource/{id}/`, */
      uri: '/promociones/promotions/hash/resource/{id}/',
      uriParams: {
        id: id
      }
    });
  },
  downloadMultiCouponImageByHash: function downloadMultiCouponImageByHash(hash) {
    return createEndpoint({
      method: methods.post,
      /* uri: `${communUriByHash}/hash/resource`, */
      uri: '/promociones/promotions/hash/resource',
      uriParams: {
        hash: hash
      }
    });
  }
};