import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_DESAFIOS, SET_PROMOCIONES, SET_CUPONES, SET_GANADOS, SET_GANADOS_CARGADOS, SET_CUPON_ACTIVO, SET_CUPON_DESCUENTO_ACTIVO, SET_GANADOS_CACHE, SET_CUPON_DESCARTADO, SET_CUPON_DESCUENTO_DESCARTADO, SET_HOME, SET_ALL_CUPON_DESCUENTO_ACTIVO } from './promociones.actions';
var initialState = {
  promociones: [],
  desafios: [],
  cupones: [],
  ganados: [],
  ganadosCache: {},
  ganadosCargados: false,
  homeOfertas: {},
  tiempoDescargarCupones: 0
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case SET_PROMOCIONES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          promociones: payload
        });
      }
    case SET_CUPONES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cupones: payload
        });
      }
    case SET_DESAFIOS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          desafios: payload
        });
      }
    case SET_GANADOS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ganados: payload,
          ganadosCargados: true
        });
      }
    case SET_GANADOS_CARGADOS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ganadosCargados: payload
        });
      }
    case SET_CUPON_ACTIVO:
      {
        var promo = payload.promo,
          tipo = payload.tipo;
        var key = tipo === 'promocion' ? 'promociones' : 'desafios';
        var array = tipo === 'promocion' ? _toConsumableArray(state.promociones) : _toConsumableArray(state.desafios);
        var index = array.findIndex(function (item) {
          return item.id === promo.id;
        });
        array[index] = promo;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, key, array));
      }
    case SET_ALL_CUPON_DESCUENTO_ACTIVO:
      {
        var updatedCupones = state.cupones.map(function (cupon) {
          return _objectSpread(_objectSpread({}, cupon), {}, {
            status: 'ACTIVE'
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          cupones: updatedCupones
        });
      }
    case SET_CUPON_DESCUENTO_ACTIVO:
      {
        var _promo = payload.promo,
          _tipo = payload.tipo;
        var _key = _tipo === 'descuento' ? 'cupones' : 'desafios';
        var _array = _tipo === 'descuento' ? _toConsumableArray(state.cupones) : _toConsumableArray(state.desafios);
        var _index = _array.findIndex(function (item) {
          return item.id === _promo.id;
        });
        _array[_index] = _promo;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _key, _array));
      }
    case SET_CUPON_DESCARTADO:
      {
        var _promo2 = payload.promo,
          _tipo2 = payload.tipo;
        var _key2 = _tipo2 === 'promocion' ? 'promociones' : 'desafios';
        var _array2 = _tipo2 === 'promocion' ? _toConsumableArray(state.promociones) : _toConsumableArray(state.desafios);
        var _index2 = _array2.findIndex(function (item) {
          return item.id === _promo2.id;
        });
        _array2.splice(_index2, 1);
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _key2, _array2));
      }
    case SET_CUPON_DESCUENTO_DESCARTADO:
      {
        var _promo3 = payload.promo;
        var _key3 = 'cupones';
        var _array3 = _toConsumableArray(state.cupones);
        var _index3 = _array3.findIndex(function (item) {
          return item.id === _promo3.id;
        });
        _array3.splice(_index3, 1);
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, _key3, _array3));
      }
    case SET_GANADOS_CACHE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ganadosCache: payload
        });
      }
    case SET_HOME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          homeOfertas: payload
        });
      }
    default:
      return state;
  }
});