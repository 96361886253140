import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _slicedToArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["action", "category", "eventType", "name"],
  _excluded2 = ["category", "error"],
  _excluded3 = ["category", "name"],
  _excluded4 = ["category", "name"],
  _excluded5 = ["category", "name"],
  _excluded6 = ["category", "name"],
  _excluded7 = ["category", "name"],
  _excluded8 = ["category", "name"],
  _excluded9 = ["category", "name"],
  _excluded10 = ["category", "name"],
  _excluded11 = ["category", "name"],
  _excluded12 = ["categoria", "subcategoria"],
  _excluded13 = ["producto", "cantidad"],
  _excluded14 = ["categoria", "subcategoria"],
  _excluded15 = ["action", "category", "name"],
  _excluded16 = ["name", "productos"],
  _excluded17 = ["producto"],
  _excluded18 = ["producto"],
  _excluded19 = ["producto", "cantidad"],
  _excluded20 = ["productoDetalle", "cantidad"],
  _excluded21 = ["productoDetalle", "cantidad"],
  _excluded22 = ["id", "nombre", "urlImagen", "puntos", "tienda"],
  _excluded23 = ["producto"],
  _excluded24 = ["nombreTienda", "productos"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable no-use-before-define  */
import fromPairs from 'lodash/fromPairs';
import pipe from 'lodash/fp/pipe';
import isNil from 'lodash/isNil';
import textNormalization from './textNormalization';
import types from './eventTypes';

/**
 * @typedef {Object} GoogleTagEvent
 * @property {string} action
 * @property {string} category
 * @property {string} eventType
 * @property {string} name
 */

/**
 * @typedef {Object} DataLayerEvent
 * @property {string} action
 * @property {string} category
 * @property {string} event
 * @property {string} name
 */

var deleteUndefinedEntries = function deleteUndefinedEntries(object) {
  return pipe(Object.entries, function (o) {
    return o.filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        value = _ref2[1];
      return value !== undefined;
    });
  }, fromPairs)(object);
};

/**
 * @param {GoogleTagEvent} event
 * @return {DataLayerEvent}
 */
export var createEvent = function createEvent(_ref3) {
  var action = _ref3.action,
    category = _ref3.category,
    eventType = _ref3.eventType,
    name = _ref3.name,
    additionalParams = _objectWithoutProperties(_ref3, _excluded);
  return deleteUndefinedEntries(_objectSpread({
    action: action,
    category: category,
    event: eventType,
    name: name
  }, additionalParams));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {ApiServerError} params.error
 * @return {DataLayerEvent}
 */
export var createFailedFormEvent = function createFailedFormEvent() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref4.category,
    error = _ref4.error,
    rest = _objectWithoutProperties(_ref4, _excluded2);
  return createEvent(_objectSpread({
    action: 'submit_form_error',
    category: category,
    eventType: types.event.website,
    error: error.toString()
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createSuccessfulFormEvent = function createSuccessfulFormEvent() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref5.category,
    name = _ref5.name,
    rest = _objectWithoutProperties(_ref5, _excluded3);
  return createEvent(_objectSpread({
    action: 'submit_form_success',
    category: category,
    eventType: types.event.website,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var checkFiltroPuntosAnaliticas = function checkFiltroPuntosAnaliticas() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref6.category,
    name = _ref6.name,
    rest = _objectWithoutProperties(_ref6, _excluded4);
  return createEvent(_objectSpread({
    action: 'click_checkbox',
    category: category,
    eventType: types.event.filterPoints,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createSubmitFormEvent = function createSubmitFormEvent() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref7.category,
    name = _ref7.name,
    rest = _objectWithoutProperties(_ref7, _excluded5);
  return createEvent(_objectSpread({
    action: 'form_submit',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createSubmitFormEventPopUpInitial = function createSubmitFormEventPopUpInitial() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref8.category,
    name = _ref8.name,
    rest = _objectWithoutProperties(_ref8, _excluded6);
  return createEvent(_objectSpread({
    action: 'open_popup',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createSubmitFormEventPopUpData = function createSubmitFormEventPopUpData() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref9.category,
    name = _ref9.name,
    rest = _objectWithoutProperties(_ref9, _excluded7);
  return createEvent(_objectSpread({
    action: 'submit_form_success',
    category: category,
    eventType: types.event.website,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createSubmitFormEventPopUp = function createSubmitFormEventPopUp() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref10.category,
    name = _ref10.name,
    rest = _objectWithoutProperties(_ref10, _excluded8);
  return createEvent(_objectSpread({
    action: 'click_popup_button',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createButtonPressEvent = function createButtonPressEvent() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref11.category,
    name = _ref11.name,
    rest = _objectWithoutProperties(_ref11, _excluded9);
  return createEvent(_objectSpread({
    action: 'click_button',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createCheckboxPressEvent = function createCheckboxPressEvent() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref12.category,
    name = _ref12.name,
    rest = _objectWithoutProperties(_ref12, _excluded10);
  return createEvent(_objectSpread({
    action: 'click_checkbox',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var createNavButtonPressEvent = function createNavButtonPressEvent() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    category = _ref13.category,
    name = _ref13.name,
    rest = _objectWithoutProperties(_ref13, _excluded11);
  return createEvent(_objectSpread({
    action: 'click_link',
    category: category,
    eventType: types.event.user,
    name: name
  }, rest));
};

/**
 * @param {Object.} params
 * @param {string} params.categoria
 * @return {{ecommerce: {impressions: Object[], currencyCode: string},
 * name: string, action: string, page: string, event: string, category: string}}
 */
export var createCatalogoCategoriaEvent = function createCatalogoCategoriaEvent(_ref14) {
  var _ref14$categoria = _ref14.categoria,
    categoria = _ref14$categoria === void 0 ? 'Catalogo' : _ref14$categoria,
    subcategoria = _ref14.subcategoria,
    params = _objectWithoutProperties(_ref14, _excluded12);
  return createCatalogoEvent(_objectSpread({
    name: "Listado ".concat(categoria).concat(subcategoria ? " > ".concat(subcategoria) : '')
  }, params));
};

/**
 *
 * @param {Object.} params
 * @param {Object[]} params.productos
 * @return {{ecommerce: {products: object[], currencyCode: string}, name: string,
 * action: string, page: string, event: string, category: string}}
 */
export var createCanjeExitosoEvent = function createCanjeExitosoEvent(_ref15) {
  var _ref15$producto = _ref15.producto,
    id = _ref15$producto.id,
    name = _ref15$producto.nombre,
    puntos = _ref15$producto.puntos,
    subcategoria = _ref15$producto.subcategoria,
    cantidad = _ref15.cantidad,
    params = _objectWithoutProperties(_ref15, _excluded13);
  return createEvent(_objectSpread({
    eventType: 'product_purchase',
    action: types.actions.load,
    category: types.categories.producto,
    name: "Producto: ".concat(textNormalization(name)),
    page: window.location.pathname,
    ecommerce: {
      currencyCode: 'CLP',
      purchase: {
        actionField: {
          id: "T".concat(new Date().getTime(), "__").concat(id),
          affiliation: 'puntoscencosud.cl',
          revenue: puntos,
          tax: 0,
          shipping: 0
        },
        products: [{
          name: textNormalization(name),
          id: id,
          price: puntos / cantidad,
          category: textNormalization(subcategoria),
          quantity: cantidad
        }]
      }
    }
  }, params));
};
export var createCatalogoProductoEvent = function createCatalogoProductoEvent(_ref16) {
  var categoria = _ref16.categoria,
    subcategoria = _ref16.subcategoria,
    params = _objectWithoutProperties(_ref16, _excluded14);
  return createProductoClickEvent(_objectSpread({
    name: "Listado ".concat(categoria).concat(subcategoria ? " > ".concat(subcategoria) : '')
  }, params));
};
export var createBusquedaProductoEvent = function createBusquedaProductoEvent(params) {
  return createProductoClickEvent(_objectSpread({
    name: 'Resultado de búsqueda'
  }, params));
};

/**
 * Para la definicion de los evenType utilizar los que se encuentra definidos en
 * eventTypes =  types.event.
 */

// ==============================================================
//  HOME PAGE
// ==============================================================
// EVENTO: CARROUSEL => user_action

/**
 * @param {Object.} [params]
 * @param {GoogleTagEvent.category} params.category
 * @param {GoogleTagEvent.name} params.name
 * @return {DataLayerEvent}
 */
export var userActionEvent = function userActionEvent() {
  var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    action = _ref17.action,
    category = _ref17.category,
    name = _ref17.name,
    rest = _objectWithoutProperties(_ref17, _excluded15);
  return createEvent(_objectSpread({
    eventType: types.event.user,
    action: textNormalization(action),
    category: textNormalization(category),
    name: textNormalization(name)
  }, rest));
};

// ==============================================================
//  FLUJO DE CANJE ONLINE
// ==============================================================
// EVENTO: category_view
/**
 *
 * @param {Object.} params
 * @param {string} params.name
 * @param {Object[]} params.productos
 * @return {{ecommerce: {impressions: object[], currencyCode: string},
 * name: string, action: string, page: string, event: string, category: string}}
 */
var createCatalogoEvent = function createCatalogoEvent(_ref18) {
  var name = _ref18.name,
    productos = _ref18.productos,
    restParams = _objectWithoutProperties(_ref18, _excluded16);
  return createEvent(_objectSpread({
    eventType: types.event.productCategoryView,
    action: types.actions.load,
    category: textNormalization(types.categories.listado),
    name: textNormalization(name),
    page: window.location.pathname,
    ecommerce: {
      currencyCode: 'CLP',
      impressions: productos.map(function (producto, index) {
        return {
          name: textNormalization(producto.nombre),
          id: producto.id,
          price: producto.puntos,
          category: textNormalization(name.replace('listado ', '')),
          position: index + 1,
          list: textNormalization(name)
        };
      })
    }
  }, restParams));
};

// EVENTO: product_click
var createProductoClickEvent = function createProductoClickEvent(_ref19) {
  var _ref19$producto = _ref19.producto,
    id = _ref19$producto.id,
    name = _ref19$producto.name,
    puntos = _ref19$producto.puntos,
    params = _objectWithoutProperties(_ref19, _excluded17);
  return createButtonPressEvent(_objectSpread({
    eventType: types.event.productClick,
    category: textNormalization(types.categories.listado),
    page: window.location.pathname,
    ecommerce: {
      currencyCode: 'CLP',
      click: {
        actionField: {
          list: textNormalization(params.name)
        },
        products: [{
          name: textNormalization(name),
          id: id,
          price: parseInt(puntos, 10),
          position: 1,
          category: textNormalization(params.name.replace('Listado ', ''))
        }]
      }
    }
  }, params));
};

// EVENTO : product_view
/**
 *
 * @param {Object.} params
 * @param {Object[]} params.productos
 * @return {{ecommerce: {products: object[], currencyCode: string},
 * name: string, action: string, page: string, event: string, category: string}}
 */
export var createProductoEvent = function createProductoEvent(_ref20) {
  var _ref20$producto = _ref20.producto,
    id = _ref20$producto.id,
    name = _ref20$producto.nombre,
    img = _ref20$producto.urlImagen,
    puntos = _ref20$producto.puntos,
    subcategoria = _ref20$producto.categoria,
    restParams = _objectWithoutProperties(_ref20, _excluded18);
  return createEvent(_objectSpread({
    eventType: types.event.productView,
    // 'product_view',
    action: types.actions.load,
    category: textNormalization(types.categories.producto),
    name: "producto: ".concat(textNormalization(name)),
    page: window.location.pathname,
    img: img,
    ecommerce: {
      currencyCode: 'CLP',
      detail: {
        actionField: {
          list: "listado productos > ".concat(textNormalization(subcategoria))
        },
        products: [{
          name: textNormalization(name),
          id: id,
          price: puntos,
          category: textNormalization(subcategoria)
        }]
      }
    }
  }, restParams));
};

// EVENTO : product_add_to_cart

/**
 *
 * @param {Object.} params
 * @param {string} params.cantidad
 * @param {Object[]} params.productos
 * @return {{ecommerce: {products: object[], currencyCode: string},
 * name: string, action: string, page: string, event: string, category: string}}
 */
export var createPreCanjeClickEvent = function createPreCanjeClickEvent(_ref21) {
  var _ref21$producto = _ref21.producto,
    id = _ref21$producto.id,
    name = _ref21$producto.nombre,
    img = _ref21$producto.urlImagen,
    puntos = _ref21$producto.puntos,
    subcategoria = _ref21$producto.categoria,
    cantidad = _ref21.cantidad,
    params = _objectWithoutProperties(_ref21, _excluded19);
  return createButtonPressEvent(_objectSpread({
    eventType: types.event.productAddToCart,
    category: textNormalization(types.categories.producto),
    name: "producto: ".concat(textNormalization(name)),
    page: window.location.pathname,
    img: img,
    ecommerce: {
      currencyCode: 'CLP',
      add: {
        products: [{
          name: textNormalization(name),
          id: id,
          price: puntos,
          category: textNormalization(subcategoria),
          quantity: cantidad
        }]
      }
    }
  }, params));
};

// EVENTO : product_checkout

/**
 *
 * @param {Object.} params
 * @param {Object[]} params.productos
 * @param {string} params.cantidad
 * @return {{ecommerce: {products: object[], currencyCode: string},
 * name: string, action: string, page: string, event: string, category: string}}
 */
export var createConfirmarCanjeEvent = function createConfirmarCanjeEvent(_ref22) {
  var _ref22$productoDetall = _ref22.productoDetalle,
    id = _ref22$productoDetall.id,
    name = _ref22$productoDetall.nombre,
    puntos = _ref22$productoDetall.puntos,
    subcategoria = _ref22$productoDetall.categoria,
    cantidad = _ref22.cantidad,
    params = _objectWithoutProperties(_ref22, _excluded20);
  return createEvent(_objectSpread({
    eventType: types.event.productoCheckout,
    action: types.actions.load,
    category: textNormalization(types.categories.checkout),
    name: textNormalization(types.names.confirmarCanje),
    page: window.location.pathname,
    ecommerce: {
      currencyCode: 'CLP',
      checkout: {
        actionField: {
          step: 1
        },
        products: [{
          name: textNormalization(name),
          id: id,
          price: puntos,
          category: textNormalization(subcategoria),
          quantity: cantidad
        }]
      }
    }
  }, params));
};
export var createConfirmarCanjeEvent2 = function createConfirmarCanjeEvent2(_ref23) {
  var _ref23$productoDetall = _ref23.productoDetalle,
    id = _ref23$productoDetall.id,
    name = _ref23$productoDetall.nombre,
    puntos = _ref23$productoDetall.puntos,
    subcategoria = _ref23$productoDetall.categoria,
    cantidad = _ref23.cantidad,
    params = _objectWithoutProperties(_ref23, _excluded21);
  return createEvent(_objectSpread({
    eventType: types.event.productoCheckout2,
    action: types.actions.load,
    category: textNormalization(types.categories.checkout),
    name: textNormalization(types.names.confirmarCanje),
    page: window.location.pathname,
    ecommerce: {
      currencyCode: 'CLP',
      checkout: {
        actionField: {
          step: 1
        },
        products: [{
          name: textNormalization(name),
          id: id,
          price: puntos,
          category: textNormalization(subcategoria),
          quantity: cantidad
        }]
      }
    }
  }, params));
};

// ==============================================================
//  FLUJO DE CANJE EN TIENDA
// ==============================================================
// EVENTO: product_tienda_view
export var createProductoTiendaEvent = function createProductoTiendaEvent(_ref24) {
  var id = _ref24.id,
    name = _ref24.nombre,
    img = _ref24.urlImagen,
    puntos = _ref24.puntos,
    tienda = _ref24.tienda,
    restParams = _objectWithoutProperties(_ref24, _excluded22);
  return createEvent(_objectSpread({
    eventType: types.event.tiendaView,
    category: textNormalization(types.categories.producto),
    action: types.actions.load,
    name: "producto: ".concat(textNormalization(name)),
    page: window.location.pathname,
    img: img
  }, restParams));
};

// EVENTO: product_tienda_click
export var createProductoTiendaClickEvent = function createProductoTiendaClickEvent(_ref25) {
  var _ref25$producto = _ref25.producto,
    id = _ref25$producto.id,
    name = _ref25$producto.name,
    tienda = _ref25$producto.tienda,
    params = _objectWithoutProperties(_ref25, _excluded23);
  return createButtonPressEvent(_objectSpread({
    eventType: types.event.tiendaClick,
    category: textNormalization(types.categories.listado),
    action: 'click_button',
    name: "listado ".concat(isNil(textNormalization(tienda)) ? textNormalization(types.names.tienda) : textNormalization(tienda)),
    page: window.location.pathname,
    ecommerce: {
      promoClick: {
        promotions: [{
          name: textNormalization(name),
          id: id,
          creative: isNil(textNormalization(tienda)) ? textNormalization(types.names.tienda) : textNormalization(tienda),
          position: 1
        }]
      }
    }
  }, params));
};

// EVENTO: tienda_view
export var createTiendaEvent = function createTiendaEvent(_ref26) {
  var tienda = _ref26.nombreTienda,
    productos = _ref26.productos,
    restParams = _objectWithoutProperties(_ref26, _excluded24);
  return createEvent(_objectSpread({
    eventType: types.event.tienda,
    category: textNormalization(types.categories.listado),
    action: types.actions.load,
    name: "listado ".concat(textNormalization(tienda)),
    page: window.location.pathname,
    ecommerce: {
      promoView: {
        promotions: productos.map(function (producto, index) {
          return {
            name: textNormalization(producto.nombre),
            id: producto.id,
            creative: "".concat(textNormalization(tienda)),
            position: index + 1
          };
        })
      }
    }
  }, restParams));
};