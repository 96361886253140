import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_CORREO_TEMPORAL, SET_TELEFONO_TEMPORAL } from '../contrasena/contrasena.actions';
import persistentStore from '../state/persistentStore';
import { APPEND_DETALLE, EDITAR_PREFERENCIAS, SET_DETALLE, SET_VALIDEZ } from './usuario.actions';
// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type === SET_DETALLE) {
    var _ref2 = payload || {},
      correo = _ref2.correo,
      telefono = _ref2.telefono,
      nombre = _ref2.nombre,
      rut = _ref2.rut,
      esPrime = _ref2.esPrime;
    if (nombre) persistentStore.setName(nombre);else persistentStore.clearName();
    if (correo) persistentStore.setCorreo(correo);else persistentStore.clearCorreo();
    if (telefono) persistentStore.setTlf(telefono);else persistentStore.clearTlf();
    if (esPrime) persistentStore.setEsPrime(esPrime);else persistentStore.clearEsPrime();
    if (rut) persistentStore.setRut(rut);else persistentStore.clearRut();
    return payload || {};
  }
  if (type === APPEND_DETALLE) {
    return _objectSpread(_objectSpread({}, state), payload);
  }
  if (type === SET_CORREO_TEMPORAL) {
    return _objectSpread(_objectSpread({}, state), {}, {
      correoTemporal: payload
    });
  }
  if (type === SET_TELEFONO_TEMPORAL) {
    return _objectSpread(_objectSpread({}, state), {}, {
      telefonoTemporal: payload
    });
  }
  if (type === EDITAR_PREFERENCIAS) {
    var call = payload.call,
      email = payload.email,
      sms = payload.sms,
      mail = payload.mail;
    return _objectSpread(_objectSpread({}, state), {}, {
      noEnviarCarta: !mail,
      noEnviarEmail: !email,
      noEnviarSMS: !sms,
      noLlamar: !call
    });
  }
  if (type === SET_VALIDEZ) {
    var validez = payload;
    return _objectSpread(_objectSpread({}, state), {}, {
      pendienteValidacionIdentidad: !validez
    });
  }
  return state;
});