import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_EXCHANGE_PRODUCT, APPEND_EXCHANGE_PRODUCT } from './exchangeProduct.actions';
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    products: [],
    totalProducts: 0
  };
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case SET_EXCHANGE_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: payload.products,
        totalProducts: payload.totalProducts
      });
    case APPEND_EXCHANGE_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: [].concat(_toConsumableArray(state.products), _toConsumableArray(payload.products)),
        totalProducts: payload.totalProducts,
        remainingProducts: state.totalProducts - payload.products.length
      });
    default:
      return state;
  }
});