export var types = {
  PUNTOS_XTRA: 1,
  MULTIPLICA: 2,
  CUPON_DESCUENTO: 3,
  CUPON_DESCUENTO_FIJO: 4
};
export var types_cupon = {
  TODO_MEDIO_PAGO: 0,
  PAGO_CON_TARJETA: 1,
  AMBOS: 2
};
export var reverseTypes = {
  1: types.PUNTOS_XTRA,
  2: types.MULTIPLICA
};
export var status = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'AVAILABLE'
};
export var tagIdToExclusive = {
  '1': true,
  '2': true,
  '3': true,
  '4': true,
  '5': true,
  '6': false,
  '7': false
};
export var getTypeById = function getTypeById(id) {
  return Object.values(types).find(function (type) {
    return type === id;
  });
};