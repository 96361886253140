import SantaIsabelIcon from './icon-3-01-santa.svg';
import searchIcon from './Icon-3-01.svg';
import ArrowDark from './Icon-3-02-dark.svg';
import ArrowPurple from './Icon-3-02-purple.svg';
import ArrowTransparent from './Icon-3-02-transparent.svg';
import DownMenu from './Icon-3-02.svg';
import SuscribeIcon from './Icon-3-04.svg';
import Profile from './Icon-3-05.svg';
import extraPoints from './Icon-3-06.svg';
import card from './Icon-3-07-B.svg';
import CloseIcon from './Icon-3-08.svg';
import ParisIcon from './Icon-3-10-blue.svg';
import JumboIcon from './Icon-3-10-green.svg';
import ExchangeIcon from './Icon-3-10-purple.svg';
import EasyIcon from './Icon-3-10-red.svg';
import storeIcon from './Icon-3-10.svg';
import PointProduct from './Icon-3-11.svg';
import Point from './Icon-3-12.svg';
import logo from './Icon-3-13.svg';
import helperIcon from './Icon-3-15.svg';
import helperIconPurple from './Icon-3-15-purple.svg';
import icoPhone from './Icon-3-16-purple-trimmed.svg';
import classicPhonePurple from './Icon-3-16-purple.svg';
import whatsapp from './Icon-3-17-purple-trimmed.svg';
import whatsAppIconPruple from './Icon-3-17-purple.svg';
import mail from './Icon-3-19-purple-trimmed.svg';
import FacebookIcon from './Icon-3-20.svg';
import TwitterIcon from './Icon-3-21.svg';
import YoutubeIcon from './Icon-3-22.svg';
import InstagramIcon from './Icon-3-23.svg';
import menuIcon from './Icon-3-25.svg';
import IconFilter from './icon-3-30.svg';
import TikTokIcon from './Icon-3-31.svg';
export { ArrowDark, ArrowPurple, ArrowTransparent, CloseIcon, DownMenu, EasyIcon, ExchangeIcon, FacebookIcon, IconFilter, InstagramIcon, JumboIcon, storeIcon, ParisIcon, Point, PointProduct, Profile, SantaIsabelIcon, SuscribeIcon, TikTokIcon, TwitterIcon, YoutubeIcon, card, classicPhonePurple, extraPoints, helperIcon, helperIconPurple, icoPhone, logo, mail, menuIcon, searchIcon, whatsAppIconPruple, whatsapp };