import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { hidePrimaryProgressBar, showPrimaryProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { GET_ESTADO_CUENTA, GET_ESTADO_CUENTA_SUCCESS, GET_ESTADO_CUENTA_FAIL, getEstadoDeCuentaSuccess, getEstadoDeCuentaFail, setEstadoDeCuenta, GET_CANJES_PENDIENTES, getCanjesPendientesSuccess, getCanjesPendientesFail, GET_CANJES_PENDIENTES_SUCCESS, GET_CANJES_PENDIENTES_FAIL, setCanjesPendientes, GET_TRANSACCIONES, getTransaccionesSuccess, getTransaccionesFail, setTransacciones, GET_TRANSACCIONES_SUCCESS, GET_TRANSACCIONES_FAIL, GET_VOUCHER_RESOURCE, getVoucherResourceSuccess, getVoucherResourceFail } from './cuenta.actions';
import { loyaltyEndpoints } from '../api/endpoints';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
export var getCanjesPendientesFlow = function getCanjesPendientesFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_CANJES_PENDIENTES) {
        return nextResponse;
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.getCanjesPendientes(getState().auth.rut), getCanjesPendientesSuccess, getCanjesPendientesFail, {
        auth: true
      }))]);
    };
  };
};
export var getTransaccionesFlow = function getTransaccionesFlow(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TRANSACCIONES) {
        return nextResponse;
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.getTransacciones(getState().auth.rut), getTransaccionesSuccess, getTransaccionesFail, {
        auth: true
      }))]);
    };
  };
};
export var processCanjesPendientes = function processCanjesPendientes(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var hideProgress = function hideProgress() {
        return dispatch(hidePrimaryProgressBar());
      };
      switch (type) {
        case GET_CANJES_PENDIENTES_SUCCESS:
          {
            var cuenta = payload.payload;
            return Promise.all([hideProgress(), dispatch(setCanjesPendientes(cuenta))]);
          }
        case GET_CANJES_PENDIENTES_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener canjes pendientes (Loyalty -> /socios/quotes/pending)'
            };
            registerEvent(event);
            return Promise.all([hideProgress(), dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getVoucherResourceFlow = function getVoucherResourceFlow(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_VOUCHER_RESOURCE) {
        return nextResponse;
      }
      var request = apiRequest(loyaltyEndpoints.canje.downloadVoucherResource(action.payload), getVoucherResourceSuccess, getVoucherResourceFail);
      return dispatch(request);
    };
  };
};
export var processTransacciones = function processTransacciones(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var hideProgress = function hideProgress() {
        return dispatch(hidePrimaryProgressBar());
      };
      switch (type) {
        case GET_TRANSACCIONES_SUCCESS:
          {
            var cuenta = payload.payload;
            return Promise.all([hideProgress(), dispatch(setTransacciones(cuenta))]);
          }
        case GET_TRANSACCIONES_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener transacciones (Loyalty -> /socios/transactions)'
            };
            registerEvent(event);
            return Promise.all([hideProgress(), dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getEstadoDeCuentaFlow = function getEstadoDeCuentaFlow(_ref6) {
  var dispatch = _ref6.dispatch,
    getState = _ref6.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_ESTADO_CUENTA) {
        return nextResponse;
      }
      if (!getState().auth.session) {
        return dispatch(pushRoute('/ingresar?redirect=puntos-por-vencer'));
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.getEstadoCuenta(getState().auth.rut), getEstadoDeCuentaSuccess, getEstadoDeCuentaFail, {
        auth: true
      }))]);
    };
  };
};
export var processEstadoDeCuenta = function processEstadoDeCuenta(_ref7) {
  var dispatch = _ref7.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var hideProgress = function hideProgress() {
        return dispatch(hidePrimaryProgressBar());
      };
      switch (type) {
        case GET_ESTADO_CUENTA_SUCCESS:
          {
            var cuenta = payload.payload;
            return Promise.all([hideProgress(), dispatch(setEstadoDeCuenta(cuenta))]);
          }
        case GET_ESTADO_CUENTA_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener estado de cuenta (Loyalty -> /socios/puntos)'
            };
            registerEvent(event);
            return Promise.all([hideProgress(), dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getEstadoDeCuentaFlow, processEstadoDeCuenta, getCanjesPendientesFlow, getTransaccionesFlow, processCanjesPendientes, processTransacciones, getVoucherResourceFlow];