var namespace = '[canje]';
export var CANJEAR = "".concat(namespace, " canjear");
export var CANJEAR_NUEVA_DIRECCION = "".concat(namespace, " canjear a nueva direccion");
export var CANJEAR_NUEVA_DIRECCION_PRINCIPAL = "".concat(namespace, " canjear a nueva direccion principal");
export var CANJEAR_SUCCESS = "".concat(CANJEAR, ": success");
export var CANJEAR_FAIL = "".concat(CANJEAR, ": fail");
export var SET_CANJE_EXITOSO = "".concat(namespace, " set exitoso");
export var SET_ERROR_CANJE = "".concat(namespace, " set error");
export var GENERAR_CODIGO = "".concat(namespace, " generar codigo");
export var GENERAR_CODIGO_SUCCESS = "".concat(GENERAR_CODIGO, " success");
export var GENERAR_CODIGO_FAIL = "".concat(GENERAR_CODIGO, " fail");
export var GENERAR_CODIGO_ECOMMERCE = "".concat(namespace, " generar codigo ecommerce");
export var GENERAR_CODIGO_ECOMMERCE_SUCCESS = "".concat(GENERAR_CODIGO_ECOMMERCE, " success");
export var GENERAR_CODIGO_ECOMMERCE_FAIL = "".concat(GENERAR_CODIGO_ECOMMERCE, " fail");
export var CANJEAR_ECOMMERCE = "".concat(namespace, " canjear ecommerce");
export var CANJEAR_ECOMMERCE_SUCCESS = "".concat(CANJEAR_ECOMMERCE, " success");
export var CANJEAR_ECOMMERCE_FAIL = "".concat(CANJEAR_ECOMMERCE, " fail");
export var CHECK_FACTIBILIDAD_DESPACHO = "".concat(namespace, " check despacho");
export var CHECK_DESPACHO_SUCCESS = "".concat(CHECK_FACTIBILIDAD_DESPACHO, " success");
export var CHECK_DESPACHO_FAIL = "".concat(CHECK_FACTIBILIDAD_DESPACHO, " fail");
export var SET_DESPACHO_MESSAGE = "".concat(namespace, " set despacho message");
export var CHECK_REDEEM = "".concat(namespace, " check redeem");
export var CHECK_REDEEM_SUCCESS = "".concat(CHECK_REDEEM, " success");
export var CHECK_REDEEM_FAIL = "".concat(CHECK_REDEEM, " fail");
export var canjear = function canjear(_ref) {
  var idProducto = _ref.idProducto,
    cantidad = _ref.cantidad,
    codigoSeguridad = _ref.codigoSeguridad,
    direccion = _ref.direccion;
  return {
    type: CANJEAR,
    payload: {
      idProducto: idProducto,
      cantidad: cantidad,
      codigoSeguridad: codigoSeguridad,
      direccion: direccion
    }
  };
};
export var canjearNuevaDireccion = function canjearNuevaDireccion(_ref2) {
  var idProducto = _ref2.idProducto,
    cantidad = _ref2.cantidad,
    direccion = _ref2.direccion,
    codigoSeguridad = _ref2.codigoSeguridad;
  return {
    type: CANJEAR_NUEVA_DIRECCION,
    payload: {
      idProducto: idProducto,
      cantidad: cantidad,
      direccion: direccion,
      codigoSeguridad: codigoSeguridad
    }
  };
};
export var canjearNuevaDireccionPrincipal = function canjearNuevaDireccionPrincipal(_ref3) {
  var idProducto = _ref3.idProducto,
    cantidad = _ref3.cantidad,
    codigoSeguridad = _ref3.codigoSeguridad,
    direccion = _ref3.direccion;
  return {
    type: CANJEAR_NUEVA_DIRECCION_PRINCIPAL,
    payload: {
      idProducto: idProducto,
      cantidad: cantidad,
      direccion: direccion,
      codigoSeguridad: codigoSeguridad
    }
  };
};
export var canjearSuccess = function canjearSuccess(canje, id, cantidad, direccion) {
  return {
    type: CANJEAR_SUCCESS,
    payload: {
      canje: canje,
      id: id,
      cantidad: cantidad,
      direccion: direccion
    }
  };
};
export var canjearFail = function canjearFail(error, id, cantidad, direccion) {
  return {
    type: CANJEAR_FAIL,
    payload: {
      error: error,
      id: id,
      cantidad: cantidad,
      direccion: direccion
    }
  };
};
export var setCanjeExitoso = function setCanjeExitoso(isExitoso) {
  return {
    type: SET_CANJE_EXITOSO,
    payload: isExitoso
  };
};
export var setErrorCanje = function setErrorCanje(error) {
  return {
    type: SET_ERROR_CANJE,
    payload: error
  };
};
export var generarCodigoSeguridad = function generarCodigoSeguridad(idProducto, cantidad, idPartner, points, codigo) {
  return {
    type: GENERAR_CODIGO,
    payload: {
      idProducto: idProducto,
      cantidad: cantidad,
      codigo: codigo
    }
  };
};
export var generarCodigoSeguridadSuccess = function generarCodigoSeguridadSuccess(success) {
  return {
    type: GENERAR_CODIGO_SUCCESS,
    payload: success
  };
};
export var generarCodigoSeguridadFail = function generarCodigoSeguridadFail(cause) {
  return {
    type: GENERAR_CODIGO_FAIL,
    payload: cause
  };
};

// CANJES ECOMMERCE (COPEC)
export var generarCodigoEcommerce = function generarCodigoEcommerce(idProducto, cantidad, idPartner, points) {
  return {
    type: GENERAR_CODIGO_ECOMMERCE,
    payload: {
      idProducto: idProducto,
      idPartner: idPartner,
      points: points,
      cantidad: cantidad
    }
  };
};
export var generarCodigoEcommerceSuccess = function generarCodigoEcommerceSuccess(success) {
  return {
    type: GENERAR_CODIGO_ECOMMERCE_SUCCESS,
    payload: success
  };
};
export var generarCodigoEcommerceFail = function generarCodigoEcommerceFail(cause) {
  return {
    type: GENERAR_CODIGO_ECOMMERCE_FAIL,
    payload: cause
  };
};
export var canjearEcommerce = function canjearEcommerce(_ref4) {
  var tlog = _ref4.tlog,
    codigoSeguridad = _ref4.codigoSeguridad,
    idProducto = _ref4.idProducto,
    cantidad = _ref4.cantidad,
    direccion = _ref4.direccion;
  return {
    type: CANJEAR_ECOMMERCE,
    payload: {
      tlog: tlog,
      codigoSeguridad: codigoSeguridad,
      idProducto: idProducto,
      cantidad: cantidad,
      direccion: direccion
    }
  };
};
export var canjearEcommerceSuccess = function canjearEcommerceSuccess(isExitoso) {
  return {
    type: CANJEAR_ECOMMERCE_SUCCESS,
    payload: isExitoso
  };
};
export var canjearEcommerceFail = function canjearEcommerceFail(cause) {
  return {
    type: CANJEAR_ECOMMERCE_FAIL,
    payload: cause
  };
};
export var checkFactibilidad = function checkFactibilidad(_ref5) {
  var sku = _ref5.sku,
    comuna = _ref5.comuna,
    cantidad = _ref5.cantidad,
    id = _ref5.id;
  return {
    type: CHECK_FACTIBILIDAD_DESPACHO,
    payload: {
      sku: sku,
      comuna: comuna,
      cantidad: cantidad,
      id: id
    }
  };
};
export var checkFactibilidadSuccess = function checkFactibilidadSuccess(response, sku, comuna, cantidad, id) {
  return {
    type: CHECK_DESPACHO_SUCCESS,
    payload: {
      response: response,
      sku: sku,
      comuna: comuna,
      cantidad: cantidad,
      id: id
    }
  };
};
export var checkFactibilidadFail = function checkFactibilidadFail(response, sku, comuna, cantidad, id) {
  return {
    type: CHECK_DESPACHO_FAIL,
    payload: {
      response: response,
      sku: sku,
      comuna: comuna,
      cantidad: cantidad,
      id: id
    }
  };
};
export var setDespachoMessage = function setDespachoMessage(message) {
  return {
    type: SET_DESPACHO_MESSAGE,
    payload: message
  };
};
export var checkRedeem = function checkRedeem(productId, quantity) {
  return {
    type: CHECK_REDEEM,
    payload: {
      productId: productId,
      quantity: quantity
    }
  };
};
export var checkRedeemSuccess = function checkRedeemSuccess(response, productId, quantity) {
  return {
    type: CHECK_REDEEM_SUCCESS,
    payload: {
      dataLimit: response,
      productId: productId,
      quantity: quantity
    }
  };
};
export var checkRedeemFail = function checkRedeemFail(response, productId, quantity) {
  return {
    type: CHECK_REDEEM_FAIL,
    payload: {
      response: response,
      productId: productId,
      quantity: quantity
    }
  };
};