import { hideNotifier, showNotifier, hideSecondaryProgressBar, showSecondaryProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { formatear } from '@cencosud/puntos-core/src/features/common/rut';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { createFailedFormEvent, createSuccessfulFormEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import { loyaltyEndpoints } from '../../api/endpoints';
import { INTENTO_REGISTRO, REGISTRO_FALLIDO, REGISTRO_EXITOSO, registroFallido, registroExitoso } from './registro.actions';
export var flujoRegistro = function flujoRegistro(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== INTENTO_REGISTRO) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        registro = _action$payload.registro,
        showSuccess = _action$payload.showSuccess;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.inscribir(), function (response) {
        return registroExitoso(response, showSuccess);
      }, registroFallido, {
        body: {
          apellido: registro.apellido,
          contrasenia: registro.contrasegna,
          email: registro.email,
          // TODO reemplazar horas y zona horaria por valor de moment
          fechaNacimiento: registro.fechaNacimiento.format('YYYY-MM-DDT12:00:00-0300'),
          nombre: registro.nombre,
          run: formatear(registro.rut),
          telefono: registro.telefono,
          tyc: registro.aceptaCondiciones,
          gRecaptchaResponse: registro.captcha,
          localEnrolment: registro.campaign,
          referido: registro.referido
        }
      }))]);
    };
  };
};
export var procesarRegistro = function procesarRegistro(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var getCmsTexts = function getCmsTexts() {
        return getState().ui.parameterize;
      };
      switch (type) {
        case REGISTRO_FALLIDO:
          {
            var error = payload;
            registerEvent(createFailedFormEvent({
              category: eventTypes.categories.usuarioNuevo,
              name: 'Inscribirme: fallo',
              error: error
            }));
            return Promise.all([dispatch(hideSecondaryProgressBar()), dispatch(showNotifier(processErrorMessage(error, getCmsTexts()))), error.isUiMessage && dispatch(showNotifier(error.message))]);
          }
        case REGISTRO_EXITOSO:
          {
            var showSuccess = payload.showSuccess;
            registerEvent(createSuccessfulFormEvent({
              category: eventTypes.categories.usuarioNuevo,
              name: 'Inscribirme: éxito'
            }));
            showSuccess();
            return Promise.all([dispatch(hideSecondaryProgressBar()), dispatch(hideNotifier())]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [flujoRegistro, procesarRegistro];