import { getDetalle } from '@cencosud/puntos-core/src/features/usuario/usuario.actions';
import { getCaptcha } from '@cencosud/ui/src/ReCaptcha/captcha.action';
import { showFullscreenProgress, hideFullscreenProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { setSession } from '../auth/auth.actions';
import { INICIALIZAR } from './store.actions';
import persistentStore from './persistentStore';
import { getCategoriasProducto } from '../productos/categorias/categorias.actions';
import { getInscriptionType } from '../registro/inscripcion.actions';
import { isWeb } from '../common/platform';
import { getHome } from '@cencosud/puntos-web/src/home/home.actions';
export var inicializarFlow = function inicializarFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== INICIALIZAR) {
        return nextResponse;
      }
      var logApiToken = persistentStore.getLogApiToken();
      return Promise.all([dispatch(showFullscreenProgress()), dispatch(getDetalle({
        fullscreenProgress: false
      })), dispatch(getCaptcha()), dispatch(getHome()), dispatch(getCategoriasProducto()), dispatch(getInscriptionType()), isWeb && dispatch(setSession(persistentStore.getSession(), persistentStore.getRut(), {
        security: {
          token: persistentStore.getSecurityApiToken(),
          refresh: persistentStore.getSecurityApiRefreshToken()
        },
        loyalty: {
          token: persistentStore.getLoyaltyApiToken(),
          refresh: persistentStore.getLoyaltyApiRefreshToken()
        },
        log: {
          token: logApiToken,
          refresh: persistentStore.getLogApiRefreshToken()
        },
        ecommerce: {
          token: persistentStore.getEcommerceApiToken(),
          refresh: persistentStore.getEcommerceApiRefreshToken()
        }
      })).then(function (r) {
        if (logApiToken && process.env.NODE_ENV !== 'test') {
          // TODO: hay que reemplazar el antiguo log acá
        }
        return r;
      })]).catch(function () {
        return undefined;
      }).finally(function () {
        return dispatch(hideFullscreenProgress());
      });
    };
  };
};
export default [inicializarFlow];