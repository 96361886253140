import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { CANJEAR, CANJEAR_FAIL, CANJEAR_NUEVA_DIRECCION, CANJEAR_NUEVA_DIRECCION_PRINCIPAL, CANJEAR_SUCCESS, canjearFail, canjearSuccess, GENERAR_CODIGO, GENERAR_CODIGO_FAIL, GENERAR_CODIGO_SUCCESS, generarCodigoSeguridadFail, generarCodigoSeguridadSuccess, setCanjeExitoso, setErrorCanje, GENERAR_CODIGO_ECOMMERCE, generarCodigoEcommerceSuccess, generarCodigoEcommerceFail, GENERAR_CODIGO_ECOMMERCE_SUCCESS, GENERAR_CODIGO_ECOMMERCE_FAIL, CANJEAR_ECOMMERCE, CHECK_FACTIBILIDAD_DESPACHO, checkFactibilidadSuccess, checkFactibilidadFail, CHECK_DESPACHO_FAIL, CHECK_DESPACHO_SUCCESS, setDespachoMessage, CHECK_REDEEM, checkRedeemSuccess, checkRedeemFail } from './canje.actions';
import { showNotifier } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import { eliminarCaracteresEspeciales as FormatearRut } from '@cencosud/puntos-core/src/features/common/rut';
import { getEstadoDeCuenta } from '@cencosud/puntos-core/src/features/cuenta/cuenta.actions';
import { getDetalle } from '@cencosud/puntos-core/src/features/usuario/usuario.actions';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import { hideAlert } from '@cencosud/puntos-core/src/features/ui/alert/alert.actions';
import { setDashboardCuenta } from '@cencosud/puntos-core/src/features/cuenta/dashboard/dashboard.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import eventTypes from '../analytics/eventTypes';
import registerEvent from '../analytics/registerEvent';
export var canjearFlow = function canjearFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== CANJEAR && type !== CANJEAR_NUEVA_DIRECCION && type !== CANJEAR_NUEVA_DIRECCION_PRINCIPAL) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        idProducto = _action$payload.idProducto,
        cantidad = _action$payload.cantidad,
        direccion = _action$payload.direccion,
        codigoSeguridad = _action$payload.codigoSeguridad,
        direccionDespacho = _action$payload.direccion;
      dispatch(setCanjeExitoso(undefined));
      var normalizeDireccion = function normalizeDireccion(_direccion) {
        var region = _direccion.region,
          comuna = _direccion.comuna,
          calle = _direccion.calle,
          numero = _direccion.numero,
          departamento = _direccion.departamento,
          provincia = _direccion.provincia;
        return {
          calle: calle,
          numero: numero,
          departamento: departamento,
          region: region.nombre,
          provincia: provincia.nombre,
          comuna: comuna.nombre
        };
      };
      var direccionConfirmada = type === CANJEAR_NUEVA_DIRECCION || type === CANJEAR_NUEVA_DIRECCION_PRINCIPAL ? normalizeDireccion(direccion) : undefined;
      return Promise.all([dispatch(setCanjeExitoso(undefined)), dispatch(apiRequest(loyaltyEndpoints.canje.canjear(getState().auth.rut), function (canje) {
        return canjearSuccess(canje, idProducto, cantidad, direccionDespacho);
      }, function (error) {
        return canjearFail(error);
      }, {
        body: {
          cantidad: cantidad,
          idProductoSites: idProducto,
          mantenerDireccion: !direccion,
          guardarDireccion: type === CANJEAR_NUEVA_DIRECCION_PRINCIPAL,
          direccion: direccionConfirmada,
          numeroDobleFactor: codigoSeguridad
        },
        auth: true
      }))]);
    };
  };
};
export var processCanje = function processCanje(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var _payload$error;
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== CANJEAR_SUCCESS && type !== CANJEAR_FAIL) {
        return nextResponse;
      }

      // errores que son considerados success
      var allowedCodes = ['COP-04'];
      if (allowedCodes.includes(payload === null || payload === void 0 ? void 0 : (_payload$error = payload.error) === null || _payload$error === void 0 ? void 0 : _payload$error.code)) {
        var id = payload.id,
          cantidad = payload.cantidad,
          direccion = payload.direccion;
        return dispatch(canjearSuccess({}, id, cantidad, direccion));
      }
      switch (type) {
        case CANJEAR_SUCCESS:
          {
            var _id = payload.id,
              _cantidad = payload.cantidad,
              _direccion2 = payload.direccion;
            // HTTP 202 (Accepted) / Sin detalle: El canje fue aceptado por SIEBEL para ser procesado.
            // Esto debiera mostrar mensaje “Su canje está siendo procesado”.
            // HTTP 204 (No content) / Sin detalle: Se produjo un Read TimeOut en la espera de la respuesta desde SIEBEL.
            // Esto debiera mostrar mensaje “Su canje está siendo procesado”,
            // si bien no tenemos certeza de si SIEBEL aceptará nuestro canje o no.
            return Promise.all([dispatch(setCanjeExitoso('success')), dispatch(pushRoute("/productos/".concat(_id, "/canje"), {
              cantidad: _cantidad,
              direccion: _direccion2
            })), dispatch(hideAlert()), dispatch(getEstadoDeCuenta()), dispatch(getDetalle()), dispatch(setDashboardCuenta(null))]);
          }
        case CANJEAR_FAIL:
          {
            var error = payload.error;
            // TODO reemplazar por código de error validación en numero doble factor
            if (error.code === 'CAN-024' || error.code === 'CAN-025') {
              return dispatch(showNotifier(processErrorMessage(error, getState().ui.parameterize)));
            }
            if (error.status.code === 'CAN-043') {
              return Promise.all([dispatch(setCanjeExitoso('failed')), dispatch(pushRoute("/exchange/failed")), dispatch(setErrorCanje(payload))]);
            }

            // FIXME notificar errores controlados
            // HTTP 403 (Forbidden) / Cód. Error: SIEBEL no acepta el canje, informando un código
            // de error hacia afuera, el cual se enviará al Front para que pinte el mensaje desde WCS
            // (p. ej. “LOY-082”: Socio no tiene puntos suficientes para el canje).
            // HTTP 408 (Request Timeout) / Sin detalle: Se produce un problema de comunicación con
            // SIEBEL, no lográndose inyectar el canje. Se debe informar al usuario usando un mensaje desde WCS.
            return Promise.all([dispatch(setCanjeExitoso('failed')), dispatch(setErrorCanje(payload))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var generarCodigoFlow = function generarCodigoFlow(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== GENERAR_CODIGO) {
        return nextResponse;
      }
      var rut = getState().auth.rut;
      if (!rut) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        idProducto = _action$payload2.idProducto,
        cantidad = _action$payload2.cantidad,
        codigo = _action$payload2.codigo;
      return dispatch(apiRequest(loyaltyEndpoints.canje.codigo.generar(), generarCodigoSeguridadSuccess, generarCodigoSeguridadFail, {
        body: {
          cantidadProductos: cantidad,
          id: FormatearRut(rut),
          idProducto: idProducto,
          siebelCode: codigo
        },
        auth: true
      }));
    };
  };
};
export var processGenerarCodigo = function processGenerarCodigo(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GENERAR_CODIGO_SUCCESS:
          {
            return nextResponse;
          }
        case GENERAR_CODIGO_FAIL:
          {
            var error = payload;
            return Promise.all([dispatch(showNotifier(processErrorMessage(error))), error.isUiMessage && dispatch(showNotifier(error.message))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var generarCodigoEcommerce = function generarCodigoEcommerce(_ref5) {
  var dispatch = _ref5.dispatch,
    getState = _ref5.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== GENERAR_CODIGO_ECOMMERCE) {
        return nextResponse;
      }
      var rut = getState().auth.rut;
      if (!rut) {
        return nextResponse;
      }
      var _action$payload3 = action.payload,
        idProducto = _action$payload3.idProducto,
        idPartner = _action$payload3.idPartner,
        points = _action$payload3.points,
        cantidad = _action$payload3.cantidad;

      // TODO: preguntar si los puntos van multiplicando por la cantidad o sin multiplicar (ahorita esta con mult)
      return dispatch(apiRequest(loyaltyEndpoints.canje.codigoEcommerce.generar(), generarCodigoEcommerceSuccess, generarCodigoEcommerceFail, {
        body: {
          wcs_id: idProducto,
          partner_name: idPartner,
          points: points,
          cantidad: cantidad
        }
      }));
    };
  };
};
export var procesarCodigoEcommerce = function procesarCodigoEcommerce(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== GENERAR_CODIGO_ECOMMERCE_SUCCESS && type !== GENERAR_CODIGO_ECOMMERCE_FAIL) {
        return nextResponse;
      }
      switch (type) {
        case GENERAR_CODIGO_ECOMMERCE_SUCCESS:
          {
            return nextResponse;
          }
        case GENERAR_CODIGO_ECOMMERCE_FAIL:
          {
            var error = action.payload;
            return Promise.all([dispatch(showNotifier(processErrorMessage(error))), error.isUiMessage && dispatch(showNotifier(error.message))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var confirmarCanjeEcommerce = function confirmarCanjeEcommerce(_ref7) {
  var dispatch = _ref7.dispatch,
    getState = _ref7.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      var rut = getState().auth.rut;
      if (type !== CANJEAR_ECOMMERCE || !rut) {
        return nextResponse;
      }
      var _action$payload4 = action.payload,
        tlog = _action$payload4.tlog,
        codigoSeguridad = _action$payload4.codigoSeguridad,
        idProducto = _action$payload4.idProducto,
        cantidad = _action$payload4.cantidad,
        direccion = _action$payload4.direccion;
      return dispatch(apiRequest(loyaltyEndpoints.canje.codigoEcommerce.validar(), function (canje) {
        return canjearSuccess(canje, idProducto, cantidad, direccion);
      }, function (error) {
        return canjearFail(error, idProducto, cantidad, direccion);
      }, {
        body: {
          t_log_id: tlog,
          otp_code: codigoSeguridad
        }
      }));
    };
  };
};
export var checkDespachoFlow = function checkDespachoFlow(_ref8) {
  var dispatch = _ref8.dispatch,
    getState = _ref8.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      var rut = getState().auth.rut;
      if (type !== CHECK_FACTIBILIDAD_DESPACHO || !rut) {
        return nextResponse;
      }
      var _action$payload5 = action.payload,
        sku = _action$payload5.sku,
        comuna = _action$payload5.comuna,
        cantidad = _action$payload5.cantidad,
        id = _action$payload5.id;
      return dispatch(apiRequest(loyaltyEndpoints.canje.checkearDespacho(rut), function (response) {
        return checkFactibilidadSuccess(response, sku, comuna, cantidad, id);
      }, function (response) {
        return checkFactibilidadFail(response, sku, comuna, cantidad, id);
      }, {
        body: {
          skuParis: sku,
          cantidad: cantidad,
          comuna: comuna
        }
      }));
    };
  };
};
export var procesarFactibilidadDespacho = function procesarFactibilidadDespacho(_ref9) {
  var dispatch = _ref9.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== CHECK_DESPACHO_FAIL && type !== CHECK_DESPACHO_SUCCESS) {
        return nextResponse;
      }
      var sku = payload.sku,
        response = payload.response,
        comuna = payload.comuna,
        cantidad = payload.cantidad,
        id = payload.id;
      switch (type) {
        case CHECK_DESPACHO_SUCCESS:
          {
            var _payload$response, _payload$response$pay, _response$payload;
            var ok = ((_payload$response = payload.response) === null || _payload$response === void 0 ? void 0 : (_payload$response$pay = _payload$response.payload) === null || _payload$response$pay === void 0 ? void 0 : _payload$response$pay.codError) === '2000' ? 'OK' : 'NOOK';
            registerEvent({
              event: eventTypes.event.productoCheckout,
              category: eventTypes.categories.canjear,
              action: eventTypes.actions.disponibilidad_shipping_method,
              name: "[".concat(ok, "|").concat(id, "|").concat(sku, "|").concat(comuna, "|").concat(cantidad, "]")
            });
            // Error traido por la api de shipping-method
            return dispatch(setDespachoMessage((response === null || response === void 0 ? void 0 : (_response$payload = response.payload) === null || _response$payload === void 0 ? void 0 : _response$payload.codError) || '500'));
          }
        case CHECK_DESPACHO_FAIL:
          {
            var _payload$response2, _payload$response2$or, _payload$response2$or2;
            // Error generico
            var code = ((_payload$response2 = payload.response) === null || _payload$response2 === void 0 ? void 0 : (_payload$response2$or = _payload$response2.originalError) === null || _payload$response2$or === void 0 ? void 0 : (_payload$response2$or2 = _payload$response2$or.payload) === null || _payload$response2$or2 === void 0 ? void 0 : _payload$response2$or2.codError) || '500';
            registerEvent({
              event: eventTypes.event.productoCheckout,
              category: eventTypes.categories.canjear,
              action: eventTypes.actions.disponibilidad_shipping_method,
              name: "[NOOK|".concat(id, "|").concat(sku, "|").concat(comuna, "|").concat(cantidad, "]")
            });
            return dispatch(setDespachoMessage(code));
          }
        default:
          return payload;
      }
    };
  };
};
export var checkRedeem = function checkRedeem(_ref10) {
  var dispatch = _ref10.dispatch,
    getState = _ref10.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      var rut = getState().auth.rut;
      if (type !== CHECK_REDEEM || !rut) {
        return nextResponse;
      }
      var _action$payload6 = action.payload,
        productId = _action$payload6.productId,
        quantity = _action$payload6.quantity;
      return dispatch(apiRequest(loyaltyEndpoints.canje.checkRedeem(), function (response) {
        return checkRedeemSuccess(response.payload, productId, quantity);
      }, function (response) {
        return checkRedeemFail(response, productId, quantity);
      }, {
        body: {
          cantidad: quantity,
          idProductoSite: productId
        }
      }));
    };
  };
};
export default [canjearFlow, processCanje, generarCodigoFlow, processGenerarCodigo, generarCodigoEcommerce, confirmarCanjeEcommerce, checkDespachoFlow, procesarFactibilidadDespacho, checkRedeem];