import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import Uri from 'urijs/src/URI';
import methods from './httpMethods';
import apiEndpointCreators from './apiEndpointCreators';
import { getCmsTextStore } from '@cencosud/ui/src/Parameterize/getText';
var createEndpoint = apiEndpointCreators.ContentEndpoint;
var createProductosEndpoint = function createProductosEndpoint(_ref) {
  var page = _ref.page,
    size = _ref.size,
    isDescendingOrder = _ref.isDescendingOrder,
    productName = _ref.productName,
    from = _ref.from,
    to = _ref.to,
    ofertas = _ref.ofertas,
    nuevos = _ref.nuevos,
    categoria = _ref.categoria,
    exclude = _ref.exclude;
  return createEndpoint({
    method: methods.get,
    uri: new Uri('/catalogo/productos').search(_objectSpread(_objectSpread(_objectSpread({
      page: page,
      size: size,
      order: function () {
        if (isNil(isDescendingOrder)) {
          return undefined;
        }
        return isDescendingOrder ? 'desc' : 'asc';
      }(),
      pro: productName,
      from: from,
      to: to,
      exclude: exclude && exclude.length > 0 ? exclude.join(',') : ''
    }, isBoolean(ofertas) ? {
      ofertas: ofertas
    } : {}), isBoolean(nuevos) ? {
      nuevos: nuevos
    } : {}), isNil(categoria) ? {} : {
      categoria: "'".concat(categoria.nombre, "'")
    }))
  });
};
export default {
  basesLegales: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/legales'
      });
    }
  },
  footer: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/footer'
      });
    }
  },
  home: {
    getMobile: function getMobile() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/mobile-home-body'
      });
    },
    getWeb: function getWeb() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/v3/home-body'
      });
    }
  },
  terminosCondiciones: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/terminos-condiciones'
      });
    }
  },
  mensajes: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/mensajes'
      });
    }
  },
  productos: {
    buscarQuery: function buscarQuery(_ref2) {
      var page = _ref2.page,
        size = _ref2.size,
        producto = _ref2.producto;
      return createEndpoint({
        method: methods.get,
        uri: "/secciones/v3/buscar?producto=".concat(encodeURI(producto), "&size=").concat(size, "&page=").concat(page)
      });
    },
    buscar: function buscar(_ref3) {
      var page = _ref3.page,
        size = _ref3.size,
        productName = _ref3.productName;
      return createProductosEndpoint({
        page: page,
        size: size,
        productName: productName,
        isDescendingOrder: false,
        from: '0',
        to: getCmsTextStore('CAT-001')
      });
    },
    catalogo: {
      get: function get(_ref4) {
        var page = _ref4.page,
          size = _ref4.size,
          from = _ref4.from,
          to = _ref4.to,
          isDescendingOrder = _ref4.isDescendingOrder,
          categoria = _ref4.categoria,
          ofertas = _ref4.ofertas,
          nuevos = _ref4.nuevos,
          excluidos = _ref4.excluidos;
        return createProductosEndpoint({
          page: page,
          size: size,
          from: from,
          to: to,
          isDescendingOrder: isDescendingOrder,
          categoria: categoria,
          ofertas: ofertas,
          nuevos: nuevos,
          exclude: excluidos
        });
      },
      getBanners: function getBanners() {
        return createEndpoint({
          method: methods.get,
          uri: '/catalogo/banners'
        });
      }
    },
    tiendas: {
      get: function get(tienda, numpag, cantreg) {
        return createEndpoint({
          method: methods.get,
          uri: "/catalogo/tienda?order=asc&tienda=".concat(tienda, "&numpag=").concat(numpag, "&cantreg=").concat(cantreg)
        });
      },
      getBanner: function getBanner() {
        return createEndpoint({
          method: methods.get,
          uri: '/catalogo/tienda/banner'
        });
      }
    },
    getCategorias: function getCategorias() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/v3/categories'
      });
    },
    getNavBarCategories: function getNavBarCategories() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/v3/categories'
      });
    },
    /**
     * @param {string} id
     * @return {ContentEndpoint}
     */
    getDetalles: function getDetalles(id) {
      return createEndpoint({
        method: methods.get,
        uri: '/catalogo/productos/{id}',
        uriParams: {
          id: id
        }
      });
    },
    getDestacados: function getDestacados() {
      return createProductosEndpoint({
        isDescendingOrder: false,
        ofertas: true,
        nuevos: true,
        size: 5
      });
    },
    getDetalleTienda: function getDetalleTienda(id) {
      return createEndpoint({
        method: methods.get,
        uri: '/catalogo/tienda/producto/{id}',
        uriParams: {
          id: id
        }
      });
    }
  },
  featureToggles: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/seccion-on-off'
      });
    }
  },
  landingPages: {
    get: function get(link) {
      return createEndpoint({
        method: methods.get,
        uri: "/secciones/landing/".concat(link)
      });
    }
  },
  landingInfo: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/landing-info'
      });
    }
  },
  landingDigitalSecurity: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/digital-security'
      });
    }
  },
  regiones: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/listas/regiones?incluirComunas=true'
      });
    }
  },
  ofertasHome: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/ofertas-personalizadas'
      });
    }
  },
  duplas: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/duplas'
      });
    }
  }
};