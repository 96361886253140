var urlEnvironment = 'https://fidelidad.cencosud.com';
export default {
  apisExternas: {
    googleMaps: {
      key: 'AIzaSyBEyNhi1CrHEOVAgWUwGTQY2OENAN7HtR8'
    },
    googleRecaptcha: {
      invisible: '6Lfk6FoaAAAAAHwQAaw85zuoptTHNPMUbTua-I0n',
      visible: '6LejYZ4UAAAAAEPK_vPNXNanuG6h5LsD3OZN2h42'
    },
    googleRecaptchav3: {
      key: '6Ldrc9oUAAAAAGj1laBwxRr7656q-uZSW-lud2G2',
      action: 'puntos_LoginForm'
    }
  },
  api: {
    publicEndpoint: '/public',
    privateEndpoint: '/private',
    key: 'CM7CJ8wMZoyAV03w5jZEnAQtF5Ju6WuC',
    cloudKey: '5udeIxHYNI23mu3jtYNFC5MxkFzMe0zPYcGuFXu4',
    kongDomain: 'api.cencosud.cl',
    cloudDomain: 'fidelidad.cencosud.com',
    identityType: {
      partner: 'puntos-cencosud-socios-partners-prod',
      default: 'puntos-cencosud-prod'
    },
    consumers: {
      android: 'fi_cl_mobile',
      ios: 'fi_cl_mobile',
      web: 'fi_cl_puntoscl'
    },
    list: {
      auth: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/security/public/login/socios"),
        kongId: undefined,
        kongName: undefined
      },
      content: {
        url: "".concat(urlEnvironment, "/fi/cl/v2/content"),
        kongId: undefined,
        kongName: undefined
      },
      contentV3: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/cms"),
        kongId: undefined,
        kongName: undefined
      },
      log: {
        url: 'https://qa-api.cencosud.cl/fi/cl/v1/puntos/log',
        kongId: 'fi.cl.puntos.log.public',
        kongName: 'fi.cl.puntos.log.public'
      },
      loyalty: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/loyalty"),
        kongId: 'fi.cl.puntos.loyalty.private',
        kongName: 'fi.cl.puntos.loyalty.private'
      },
      security: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/security"),
        kongId: 'fi.cl.puntos.security.private',
        kongName: 'fi.cl.puntos.security.private'
      },
      ecommerce: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/puntos/ecommerce"),
        kongId: undefined,
        kongName: undefined
      },
      // Ofertas en teoría no vive en loyalty, fue un tema de convención.
      // No consume ambiente (/test, /dev) y no consume endpoints publicos (/public) o privados (/private)
      ofertas: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/loyalty/ofertas"),
        /* url: `${urlEnvironment}/fi/cl/v2/offers`, */
        kongId: undefined,
        kongName: undefined
      },
      ofertasStream: {
        url: "".concat(urlEnvironment, "/fi/cl/v1/offers/public"),
        /* url: `${urlEnvironment}/fi/cl/v2/offers`, */
        kongId: undefined,
        kongName: undefined
      },
      rss: {
        url: "".concat(urlEnvironment, "/rss/puntos"),
        kongId: undefined,
        kongName: undefined
      }
    }
  },
  sesion: {
    inactividad: {
      /*
            tiempo en milisegundos, cualquier valor menor a 1 inhabilita la característica de
            cierre de sesión por inactividad.
             */
      tiempoAntesNotificacion: 60000 * 4.5,
      // tiempo en milisegundos
      tiempoCuentaRegresiva: 30000
    }
  }
};