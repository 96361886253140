import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { SET_SESSION } from './auth.actions';
import { SET_COPEC } from '../productos/ProductoDetalle/productoDetalle.actions';
import persistentStore from '../state/persistentStore';
var persistApiCredentials = function persistApiCredentials(payload) {
  var api = payload.api;
  if (isNil(api)) {
    persistentStore.setSecurityApiToken(undefined);
    persistentStore.setLoyaltyApiToken(undefined);
    persistentStore.setLoyaltyApiRefreshToken(undefined);
    persistentStore.setSecurityApiRefreshToken(undefined);
    persistentStore.setLogApiToken(undefined);
    persistentStore.setLogApiRefreshToken(undefined);
    persistentStore.setEcommerceApiToken(undefined);
    persistentStore.setEcommerceApiRefreshToken(undefined);
    return;
  }
  if (get(api, 'log')) {
    persistentStore.setLogApiToken(get(api, 'log.token'));
    persistentStore.setLogApiRefreshToken(get(api, 'log.refresh'));
  }
  if (get(api, 'loyalty')) {
    persistentStore.setLoyaltyApiToken(get(api, 'loyalty.token'));
    persistentStore.setLoyaltyApiRefreshToken(get(api, 'loyalty.refresh'));
  }
  if (get(api, 'security')) {
    persistentStore.setSecurityApiToken(get(api, 'security.token'));
    persistentStore.setSecurityApiRefreshToken(get(api, 'security.refresh'));
  }
  if (get(api, 'ecommerce')) {
    persistentStore.setEcommerceApiToken(get(api, 'ecommerce.token'));
    persistentStore.setEcommerceApiRefreshToken(get(api, 'ecommerce.refresh'));
  }
};
var initialState = {
  authCopec: false,
  copecCalled: false
};

// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  switch (type) {
    case SET_SESSION:
      {
        var session = payload.session,
          rut = payload.rut;
        persistentStore.setSession(session);
        persistentStore.setRut(rut);
        persistApiCredentials(payload);
        sessionStorage.removeItem('configApp');
        if (!session) {
          persistentStore.clearCorreo();
          persistentStore.clearName();
          persistentStore.clearTlf();
          persistentStore.clearEsPrime();
        }
        ;
        return _objectSpread(_objectSpread({}, state), {}, {
          session: session,
          rut: rut,
          copecCalled: session ? state.copecCalled : false,
          authCopec: session ? state.authCopec : false
        });
      }
    case SET_COPEC:
      return _objectSpread(_objectSpread({}, state), {}, {
        authCopec: payload,
        copecCalled: true
      });
    default:
      return state;
  }
});