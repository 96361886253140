var namespace = '[exchange product]';
export var GET_EXCHANGE_PRODUCT = "".concat(namespace, " get exchange product");
export var GET_EXCHANGE_PRODUCT_SUCCESS = "".concat(GET_EXCHANGE_PRODUCT, ": success");
export var GET_EXCHANGE_PRODUCT_FAIL = "".concat(GET_EXCHANGE_PRODUCT, ": fail");
export var SET_EXCHANGE_PRODUCT = "".concat(namespace, " set exchange product");
export var GET_APPEND_EXCHANGE_PRODUCT = "".concat(namespace, " append exchange product");
export var APPEND_EXCHANGE_PRODUCT_SUCCESS = "".concat(GET_APPEND_EXCHANGE_PRODUCT, ": success");
export var APPEND_EXCHANGE_PRODUCT_FAIL = "".concat(GET_APPEND_EXCHANGE_PRODUCT, ": fail");
export var APPEND_EXCHANGE_PRODUCT = "".concat(namespace, " set append exchange product");
export var getExchangeProduct = function getExchangeProduct(_ref) {
  var page = _ref.page,
    size = _ref.size,
    from = _ref.from,
    to = _ref.to,
    exchangeType = _ref.exchangeType,
    category = _ref.category,
    store = _ref.store,
    _ref$asc = _ref.asc,
    asc = _ref$asc === void 0 ? true : _ref$asc,
    query = _ref.query;
  return {
    type: GET_EXCHANGE_PRODUCT,
    payload: {
      page: page,
      size: size,
      from: from,
      to: to,
      exchangeType: exchangeType,
      category: category,
      store: store,
      asc: asc,
      query: query
    }
  };
};
export var getExchangeProductSuccess = function getExchangeProductSuccess(exchangeProduct) {
  return {
    type: GET_EXCHANGE_PRODUCT_SUCCESS,
    payload: exchangeProduct
  };
};
export var getExchangeProductFail = function getExchangeProductFail(cause) {
  return {
    type: GET_EXCHANGE_PRODUCT_FAIL,
    payload: cause
  };
};
export var setExchangeProduct = function setExchangeProduct(products, totalProducts) {
  return {
    type: SET_EXCHANGE_PRODUCT,
    payload: {
      products: products,
      totalProducts: totalProducts
    }
  };
};
export var getAppendExchangeProduct = function getAppendExchangeProduct(_ref2) {
  var page = _ref2.page,
    size = _ref2.size,
    from = _ref2.from,
    to = _ref2.to,
    exchangeType = _ref2.exchangeType,
    category = _ref2.category,
    store = _ref2.store,
    _ref2$asc = _ref2.asc,
    asc = _ref2$asc === void 0 ? true : _ref2$asc,
    query = _ref2.query;
  return {
    type: GET_APPEND_EXCHANGE_PRODUCT,
    payload: {
      page: page,
      size: size,
      from: from,
      to: to,
      exchangeType: exchangeType,
      category: category,
      store: store,
      asc: asc,
      query: query
    }
  };
};
export var appendExchangeProductSuccess = function appendExchangeProductSuccess(products) {
  return {
    type: APPEND_EXCHANGE_PRODUCT_SUCCESS,
    payload: products
  };
};
export var appendExchangeProductFail = function appendExchangeProductFail(products) {
  return {
    type: APPEND_EXCHANGE_PRODUCT_FAIL,
    payload: products
  };
};
export var appendExchangeProduct = function appendExchangeProduct(products, skipedProducts, totalProducts) {
  return {
    type: APPEND_EXCHANGE_PRODUCT,
    payload: {
      products: products,
      skipedProducts: skipedProducts,
      totalProducts: totalProducts
    }
  };
};