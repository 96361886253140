/* eslint-disable */
// TEXTOS PARA PUNTOS CENCOSUD

var textos = {
  busqueda: {
    resultadoTitulo: 'Resultado de búsqueda'
  },
  canje: {
    titulo: 'Detalle de tu canje',
    subtitulo: 'Información de tu canje',
    detalle: 'Detalles de tu canje:',
    puntosDescontar: 'Puntos a descontar:',
    correoCambiar: 'Cambiar correo',
    direccionDespacho: 'Dirección de despacho',
    direccionDespachoCambiar: 'Cambiar dirección',
    establecerDireccionPrincipal: 'Establecer esta dirección como dirección principal',
    enviamosVoucher: 'Hemos enviado tu voucher a:',
    enviamosCorreo: 'Enviaremos un correo electrónico a:',
    email: 'Tu canje será enviado al correo electrónico registrado en tu cuenta',
    emailTip: {
      body: 'Si ya no tienes acceso a este correo, puedes actualizarlo ',
      link: 'aquí'
    },
    comunas: 'Despacho No disponible para las siguientes comunas:\n' + 'Camarones, Camiña, Colchane, General Lagos, Huara, Pica, Putre, Taltal, Rapanui, Chaitén, Futaleufú, Hualaihue, Palena, Aysén, Chile Chico, Cisnes, Cochrane, Coyhaique, Guaitecas, Lago Verde, O´higgins, Río Ibañez, Tortel, Antártica, Laguna Blanca, Natales, Navarino, Porvenir, Primavera,  Williams, Punta Arenas, Río Verde, San Gregorio, Timaukel, Torres del Paine, Domeiko, Corral, Quellón.',
    condiciones: 'Estimado Cliente: Recuerde que tienes las siguientes garantías: Devolución: Debe ser dentro de las primeras 24 hrs. desde la recepción. Producto debe estar sin uso y con embalaje intacto. Daño: Dentro de las 24 horas desde la recepción del producto. Se descarta el daño vinculado a la mala manipulación del producto. Satisfacción total (no cumple con lo ofrecido): Cambio o devolución dentro de los 10 días desde la recepción del producto. Falla: Cambio o devolución dentro de los 10 días desde la recepción del producto. Pasado los 10 días el producto se deriva a Servicio Técnico; Recuerde siempre Revisar bien su producto al momento de la recepción y en caso de cualquier inconveniente notificar a Puntos Cencosud al 600 360 4000.'
  },
  general: {
    cantidad: 'Cantidad: ',
    productos: 'productos',
    seEncontraron: 'Hemos encontrado los siguientes productos que coinciden con',
    coincidenCon: 'que coinciden con',
    region: 'Región',
    comuna: 'Comuna',
    calle: 'Calle',
    numero: 'Número',
    depto: 'Casa/Block/Depto',
    puntos: 'puntos',
    email: 'Correo electrónico',
    entrega: 'Dirección de despacho:',
    volverAHome: 'Volver al Home',
    verCatalogo: 'Ver catálogo'
  }
};
export default (function () {
  return textos;
});