import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import storeMdl from '@cencosud/puntos-core/src/features/state/store.mdl';
import apiMdl from '@cencosud/puntos-core/src/features/api/api.mdl';
import authMdl from '@cencosud/puntos-core/src/features/auth/auth.mdl';
import registroMdlV1 from '@cencosud/puntos-core/src/features/registro/v1/registro.mdl';
import registroMdlV2 from '@cencosud/puntos-core/src/features/registro/v2/registro.mdl';
import contrasenaMdl from '@cencosud/puntos-core/src/features/contrasena/contrasena.mdl';
import cambiarContrasenaMdl from '@cencosud/puntos-core/src/features/contrasena/CambiarContrasena/cambiarContrasena.mdl';
import historyMdl from '@cencosud/puntos-core/src/features/router/history.mdl';
import usuarioMdl from '@cencosud/puntos-core/src/features/usuario/usuario.mdl';
import homeMdl from '../home/home.mdl';
import alertMdl from '@cencosud/puntos-core/src/features/ui/alert/alert.mdl';
import footerMdl from '@cencosud/puntos-core/src/features/ui/footer/footer.mdl';
import catalogoMdl from '@cencosud/puntos-core/src/features/catalogo/catalogo.mdl';
import exchangeProductsMdl from '@cencosud/puntos-core/src/features/exchangeProduct/exchangeProduct.mdl';
import productoMdl from '@cencosud/puntos-core/src/features/productos/ProductoDetalle/productoDetalle.mdl';
import busquedaMdl from '@cencosud/puntos-core/src/features/busqueda/busqueda.mdl';
import productoDestacadoMdl from '@cencosud/puntos-core/src/features/productos/ProductoDestacado/productoDestacado.mdl';
import usuarioAdicionalesMdl from '../sociosAdicionales/AdministrarAdicionales/usuarioAdicionales.mdl';
import agregarUsuarioAdicionalMdl from '../sociosAdicionales/AgregarAdicional/agregarAdicional.mdl';
import cuentaMdl from '@cencosud/puntos-core/src/features/cuenta/cuenta.mdl';
import canjeMdl from '../canje/canje.mdl';
import parameterize from '@cencosud/ui/src/Parameterize/parameterize.mdl';
import captchaMdl from '@cencosud/ui/src/ReCaptcha/captcha.mdl';
import TyCMdl from '@cencosud/puntos-core/src/features/registro/TyC/TyC.mdl';
import categoriasMdl from '@cencosud/puntos-core/src/features/productos/categorias/categorias.mdl';
import categoriasV3Mdl from '@cencosud/puntos-core/src/features/categories-v3/categorias/categorias.mdl';
import adicionalesPendientesMdl from '../sociosAdicionales/AdicionalesPendientes/adicionalesPendites.mdl';
import featuresTogglesMdl from '@cencosud/puntos-core/src/features/featureToggles/featuresToggles.mdl';
import AutogestionVouchersMdl from '../autogestionVouchers/AutogestionVouchers.mdl';
import duplaMdl from '@cencosud/puntos-core/src/features/registro/dupla/dupla.mdl';
import AccountStatusMdl from '@cencosud/puntos-core/src/features/accountStatus/accountStatus.mdl';
import basicPageMdl from '@cencosud/puntos-core/src/features/basicPages/basicPage.mdl';
import regionesMdl from '@cencosud/puntos-core/src/features/common/regiones/regiones.mdl';
import dasboardMdl from '@cencosud/puntos-core/src/features/cuenta/dashboard/dasboard.mdl';
import tiendaMdl from '@cencosud/puntos-core/src/features/tienda/tienda.mdl';
import productoTiendaMdl from '@cencosud/puntos-core/src/features/productos/ProductoTienda/productoTienda.mdl';
import informacionesMdl from '@cencosud/puntos-core/src/features/informaciones/informaciones.mdl';
import seguridadDigitalMdl from '@cencosud/puntos-core/src/features/seguridad-digital/seguridadDigital.mdl';
import inscripcionMdl from '@cencosud/puntos-core/src/features/registro/inscripcion.mdl';
import promocionesMdl from '@cencosud/puntos-core/src/features/promociones/promociones.mdl';
var middlewares = [].concat(_toConsumableArray(storeMdl), _toConsumableArray(apiMdl), _toConsumableArray(authMdl), _toConsumableArray(inscripcionMdl), _toConsumableArray(registroMdlV1), _toConsumableArray(registroMdlV2), _toConsumableArray(contrasenaMdl), _toConsumableArray(cambiarContrasenaMdl), _toConsumableArray(historyMdl), _toConsumableArray(usuarioMdl), _toConsumableArray(homeMdl), _toConsumableArray(alertMdl), _toConsumableArray(footerMdl), _toConsumableArray(catalogoMdl), _toConsumableArray(productoMdl), _toConsumableArray(busquedaMdl), _toConsumableArray(productoDestacadoMdl), _toConsumableArray(usuarioAdicionalesMdl), _toConsumableArray(agregarUsuarioAdicionalMdl), _toConsumableArray(cuentaMdl), _toConsumableArray(canjeMdl), _toConsumableArray(parameterize), _toConsumableArray(TyCMdl), _toConsumableArray(adicionalesPendientesMdl), _toConsumableArray(categoriasMdl), _toConsumableArray(categoriasV3Mdl), _toConsumableArray(featuresTogglesMdl), _toConsumableArray(AutogestionVouchersMdl), _toConsumableArray(regionesMdl), _toConsumableArray(dasboardMdl), _toConsumableArray(tiendaMdl), _toConsumableArray(productoTiendaMdl), _toConsumableArray(informacionesMdl), _toConsumableArray(seguridadDigitalMdl), _toConsumableArray(promocionesMdl), _toConsumableArray(duplaMdl), _toConsumableArray(captchaMdl), _toConsumableArray(AccountStatusMdl), _toConsumableArray(basicPageMdl), _toConsumableArray(exchangeProductsMdl));

// Middlewares desarrollo
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  var logger = require('redux-logger');
  middlewares.push(logger.default);
}
var devToolsComposer = typeof window !== 'undefined' && window &&
// eslint-disable-next-line no-underscore-dangle
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
var composeEnhancers = devToolsComposer && devToolsComposer({
  serialize: true
}) || compose;
var store = createStore(combineReducers(_objectSpread({}, reducers)), composeEnhancers(applyMiddleware.apply(void 0, _toConsumableArray(middlewares).concat([thunk]))));
export default store;