import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { combineReducers } from 'redux';
import auth from '@cencosud/puntos-core/src/features/auth/auth.reducer';
import { notifierReducer, progressBarReducer, primaryProgressReducer, secondaryProgressReducer, fullscreenProgressReducer, toastReducer } from '@cencosud/puntos-core/src/features/ui/ui.reducer';
import basesLegales from '@cencosud/puntos-core/src/features/basesLegales/reducer';
import alert from '@cencosud/puntos-core/src/features/ui/alert/alert.reducer';
import usuario from '@cencosud/puntos-core/src/features/usuario/usuario.reducer';
import home from '../home/home.reducer';
import footer from '@cencosud/puntos-core/src/features/ui/footer/footer.reducer';
import catalogo from '@cencosud/puntos-core/src/features/catalogo/catalogo.reducer';
import producto from '@cencosud/puntos-core/src/features/productos/ProductoDetalle/producto.reducer';
import buscador from '@cencosud/puntos-core/src/features/busqueda/busqueda.reducer';
import productoDestacado from '@cencosud/puntos-core/src/features/productos/ProductoDestacado/productoDestacado.reducer';
import usuarioAdicionales from '../sociosAdicionales/AdministrarAdicionales/usuarioAdicionales.reducer';
import estadoDeCuenta from '@cencosud/puntos-core/src/features/cuenta/cuenta.reducer';
import canje from '../canje/canje.reducer';
import parameterize from '@cencosud/ui/src/Parameterize/parameterize.reducer';
import TyC from '@cencosud/puntos-core/src/features/registro/TyC/TyC.reducer';
import categorias from '@cencosud/puntos-core/src/features/productos/categorias/categorias.reducer';
import categoriasV3 from '@cencosud/puntos-core/src/features/categories-v3/categorias/categorias.reducer';
import adicionalesPendientes from '../sociosAdicionales/AdicionalesPendientes/adicionalesPendites.reducer';
import campagna from '@cencosud/ui/src/Campagna/campagna.reducer';
import featureToggles from '@cencosud/puntos-core/src/features/featureToggles/featureToggles.reducer';
import AutogestionVouchers from '../autogestionVouchers/AutogestionVouchers.reducer';
import regiones from '@cencosud/puntos-core/src/features/common/regiones/regiones.reducer';
import duplas from '@cencosud/puntos-core/src/features/registro/dupla/dupla.reducer';
import dashboard from '@cencosud/puntos-core/src/features/cuenta/dashboard/dashboard.reducer';
import tienda from '@cencosud/puntos-core/src/features/tienda/tienda.reducer';
import productoTienda from '@cencosud/puntos-core/src/features/productos/ProductoTienda/productoTienda.reducer';
import informacion from '@cencosud/puntos-core/src/features/informaciones/informaciones.reducer';
import seguridadDigital from '@cencosud/puntos-core/src/features/seguridad-digital/seguridadDigital.reducer';
import inscripcion from '@cencosud/puntos-core/src/features/registro/inscripcion.reducer';
import captcha from '@cencosud/ui/src/ReCaptcha/captcha.reducer';
import accountStatus from '@cencosud/puntos-core/src/features/accountStatus/accountStatus.reducer';
import loading from '@cencosud/puntos-core/src/features/loading/loading.reducer';
import reCaptcha from '@cencosud/puntos-core/src/features/reCaptchav3/reCaptchav3.reducer';
import ofertas from '@cencosud/puntos-core/src/features/promociones/promociones.reducer';
import basicPage from '@cencosud/puntos-core/src/features/basicPages/basicPage.reducer';
import exchangeProducts from '@cencosud/puntos-core/src/features/exchangeProduct/exchangeProduct.reducer';
var reducers = _objectSpread(_objectSpread(_objectSpread({
  auth: auth,
  basesLegales: basesLegales,
  ui: combineReducers({
    notifier: notifierReducer,
    toast: toastReducer,
    progressBar: progressBarReducer,
    progress: combineReducers({
      primary: primaryProgressReducer,
      secondary: secondaryProgressReducer,
      fullscreen: fullscreenProgressReducer
    }),
    alert: alert,
    parameterize: parameterize,
    campagna: campagna,
    inscripcion: inscripcion
  }),
  user: usuario,
  home: home,
  footer: footer,
  catalogo: catalogo,
  producto: producto,
  resultadosBusqueda: buscador,
  productoDestacado: productoDestacado,
  // TODO mover dentro de `user.adicionales`
  adicionales: usuarioAdicionales,
  pendientes: adicionalesPendientes,
  cuenta: estadoDeCuenta,
  dashboard: dashboard,
  canje: canje,
  TyC: TyC,
  categorias: categorias,
  categoriasV3: categoriasV3,
  featureToggles: featureToggles,
  vouchers: AutogestionVouchers,
  regiones: regiones,
  duplas: duplas,
  tienda: tienda,
  productoTienda: productoTienda,
  informacion: informacion,
  seguridadDigital: seguridadDigital,
  captcha: captcha
}, loading), reCaptcha), {}, {
  ofertas: ofertas,
  accountStatus: accountStatus,
  basicPage: basicPage,
  exchangeProducts: exchangeProducts
});
export default reducers;