import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, getTransactionsSuccess, getTransactionsFail, setTransactions, GET_DASHBOARDS, GET_DASHBOARDS_SUCCESS, getDashboardsSuccess, getDashboardsFail, setDashboards, GET_TRANSACTIONS_AND_TOTALS_SUCCESS, setTransactionsAndTotals, getTransactionsAndTotalsSuccess, getTransactionsAndTotalsFail, GET_TRANSACTIONS_AND_TOTALS } from './accountStatus.actions';
import { loyaltyEndpoints } from '../api/endpoints';
export var getTransactionsFlow = function getTransactionsFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TRANSACTIONS) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        startDate = _action$payload.startDate,
        endDate = _action$payload.endDate,
        boardName = _action$payload.boardName,
        pagNum = _action$payload.pagNum,
        pagSize = _action$payload.pagSize;
      return dispatch(apiRequest(loyaltyEndpoints.user.statusAccout.getTransactions(), getTransactionsSuccess, getTransactionsFail, {
        body: {
          startDate: startDate,
          endDate: endDate,
          boardName: boardName,
          pagNumber: pagNum,
          pagSize: pagSize
        },
        auth: true
      }));
    };
  };
};
export var processTransaction = function processTransaction(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_TRANSACTIONS_SUCCESS:
          {
            var transactions = payload.payload;
            return dispatch(setTransactions(transactions));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getTransactionsAndTotalsFlow = function getTransactionsAndTotalsFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TRANSACTIONS_AND_TOTALS) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        startDate = _action$payload2.startDate,
        endDate = _action$payload2.endDate,
        boardName = _action$payload2.boardName,
        pagNum = _action$payload2.pagNum,
        pagSize = _action$payload2.pagSize;
      return dispatch(apiRequest(loyaltyEndpoints.user.statusAccout.getTransactionsAndTotals(), getTransactionsAndTotalsSuccess, getTransactionsAndTotalsFail, {
        body: {
          startDate: startDate,
          endDate: endDate,
          boardName: boardName,
          pagNumber: pagNum,
          pagSize: pagSize
        },
        auth: true
      }));
    };
  };
};
export var processTransactionAndTotals = function processTransactionAndTotals(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_TRANSACTIONS_AND_TOTALS_SUCCESS:
          {
            var transactions = payload.payload;
            return dispatch(setTransactionsAndTotals(transactions));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getDashboardsFlow = function getDashboardsFlow(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DASHBOARDS) {
        return nextResponse;
      }
      return dispatch(apiRequest(loyaltyEndpoints.user.statusAccout.getDashboards(), getDashboardsSuccess, getDashboardsFail, {
        auth: true
      }));
    };
  };
};
export var processDashboard = function processDashboard(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_DASHBOARDS_SUCCESS:
          {
            var dashboard = payload.payload;
            return dispatch(setDashboards(dashboard.result));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getTransactionsFlow, processTransaction, getTransactionsAndTotalsFlow, processTransactionAndTotals, getDashboardsFlow, processDashboard];