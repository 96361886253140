import { apiRequest } from '../../api/api.actions';
import { GET_PRODUCTO_TIENDA, GET_PRODUCTO_TIENDA_FAIL, GET_PRODUCTO_TIENDA_SUCCESS, setProductoTienda, getProductoTiendaFail, getProductoTiendaSuccess } from './productoTienda.actions';
import history from '../../router/history';
import AuthenticationError from '../../auth/AuthenticationError';
import { contentV3Endpoints } from '../../api/endpoints';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import productStoreBinder from '@cencosud/puntos-core/src/features/binder/ProductStoreBinder';
import productExchangeToStoreProductBinder from '@cencosud/puntos-core/src/features/binder/ProductExchangeToStoreProductBinder';
import { getCmsText } from '@cencosud/ui/src/Parameterize/getText';
export var getProductoTiendaFlow = function getProductoTiendaFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PRODUCTO_TIENDA) {
        return nextResponse;
      }
      var endpoint = getCmsText('temp-001') === '1' ? contentV3Endpoints.exchangeProducts.get(action.payload.id) : contentV3Endpoints.productos.getDetalleTienda(action.payload.id);
      return dispatch(apiRequest(endpoint, getProductoTiendaSuccess, getProductoTiendaFail));
    };
  };
};
export var processGetProductoTienda = function processGetProductoTienda(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PRODUCTO_TIENDA_SUCCESS:
          {
            var productoTienda = payload.payload;
            var productBinder = getCmsText('temp-001') === '1' ? productExchangeToStoreProductBinder(productoTienda) : productStoreBinder(productoTienda);
            return dispatch(setProductoTienda(productBinder));
          }
        case GET_PRODUCTO_TIENDA_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener detalle de producto en tienda (Content -> /cartalogo/tienda/producto)'
            };
            registerEvent(event);
            return dispatch(history.push('/error'));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getProductoTiendaFlow, processGetProductoTienda];