import isObject from 'lodash/isObject';
import { tiposProducto } from '@cencosud/puntos-core/src/features/productos/tiposProducto';
var getType = function getType(product) {
  switch (product.dispatch) {
    case 'Domicilio':
      return tiposProducto.REGULAR;
    case 'Email':
      return tiposProducto.SERVICIO;
    default:
      return undefined;
  }
};
var bindUrlImage = function bindUrlImage(urlImage) {
  var _urlImage$imageDetail;
  return isObject(urlImage) ? (_urlImage$imageDetail = urlImage.imageDetail1) === null || _urlImage$imageDetail === void 0 ? void 0 : _urlImage$imageDetail.url : '';
};
var bindImages = function bindImages(images) {
  if (isObject(images)) {
    var _images$imageDetail, _images$imageDetail2, _images$imageDetail3, _images$imageDetail4;
    var imagesGallery = [];
    if (isObject(images.imageDetail1)) imagesGallery.push((_images$imageDetail = images.imageDetail1) === null || _images$imageDetail === void 0 ? void 0 : _images$imageDetail.url);
    if (isObject(images.imageDetail2)) imagesGallery.push((_images$imageDetail2 = images.imageDetail2) === null || _images$imageDetail2 === void 0 ? void 0 : _images$imageDetail2.url);
    if (isObject(images.imageDetail3)) imagesGallery.push((_images$imageDetail3 = images.imageDetail3) === null || _images$imageDetail3 === void 0 ? void 0 : _images$imageDetail3.url);
    if (isObject(images.imageDetail)) imagesGallery.push((_images$imageDetail4 = images.imageDetail4) === null || _images$imageDetail4 === void 0 ? void 0 : _images$imageDetail4.url);
    return imagesGallery;
  }
  return [];
};
export default (function (item) {
  var _item$categories$, _item$categories$2;
  return isObject(item) ? {
    id: item.id,
    nombre: item.productName,
    altText: item.productName,
    descripcion: item.description,
    especificaciones: item.specifications,
    condicionesDeCanje: item.exchangeConditions,
    puntos: item.points,
    puntosRegular: item.regularPoints,
    puntosPrime: item.priceLoyaltyClient,
    precioTachado: item.strikethroughPoints,
    copago: item.copayPrice,
    mostrarPorcentaje: item.showDiscountPercentage,
    nombreCategoria: item.categories ? (_item$categories$ = item.categories[0]) === null || _item$categories$ === void 0 ? void 0 : _item$categories$.name : 'Sin Categoría',
    // TODO: Homologar en una categoría (vitrina,catalogo,canjeonline) o posiblemente eliminar (no se utiliza parece)
    categoria: item.categories ? (_item$categories$2 = item.categories[0]) === null || _item$categories$2 === void 0 ? void 0 : _item$categories$2.name : 'Sin Categoría',
    // TODO: Homologar en una categoría (detalle) o  posiblemente eliminar (no se utiliza parece)
    urlImagen: bindUrlImage(item.productImage),
    tagNuevo: item.newProductTag,
    tagOferta: item.offerProductTag,
    tagHome: item.tagHome,
    imagenes: bindImages(item.productImage),
    despacho: item.dispatch,
    tipo: getType(item),
    stock: item.stock !== undefined && item.stock !== null ? item.stock : undefined,
    direccion: item.addressLayout,
    tipoLayout: item.layoutType,
    productoEcommerce: item.ecommerceProduct,
    idPartner: item.partner,
    marca: item.brand ? item.brand : 0,
    skuParis: item.productSKU,
    variante: item.variant,
    icono: item.productIcon,
    codigo: item.rowId,
    disableQuantity: !item.disableQuantity,
    stockHandler: item.stockHandler
  } : {};
});