import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var localStorage = typeof window === 'undefined' ? {
  getItem: function getItem() {
    return undefined;
  },
  setItem: function setItem() {
    return undefined;
  },
  removeItem: function removeItem() {
    return undefined;
  }
} : window.localStorage;
var keys = {
  ASYNC_TRANSACTIONS: 'asyncTransactions',
  LOG_API_TOKEN: 'logApiSession',
  LOG_API_REFRESH_TOKEN: 'logApiRefreshToken',
  LOYALTY_API_TOKEN: 'loyaltyApiSession',
  LOYALTY_API_REFRESH_TOKEN: 'loyaltyRefreshApiSession',
  RUT: 'rut',
  NOMBRE: 'puntosName',
  TELEFONO: 'puntosTlf',
  PRIME: 'esPrime',
  CORREO: 'puntosCorreo',
  SECURITY_API_TOKEN: 'securityApiSession',
  ECOMMERCE_API_TOKEN: 'ecommerceApiSession',
  ECOMMERCE_API_REFRESH_TOKEN: 'ecommerceRefreshApiSession',
  SECURITY_API_REFRESH_TOKEN: 'securityRefreshApiSession',
  SESSION: 'session',
  TRANSACCIONES: 'transacciones',
  BOT_CONTEXT: 'bot_context',
  INFO_CONV: 'infoConversacion',
  INFO_CONV_ESPERA: 'infoConversacionEnEspera',
  CONFIG_CC: 'configApp',
  ADDITIONAL_ID: 'additionalId',
  ADDITIONAL_NAME: 'additionalName'
};
export var getItem = function getItem(key) {
  var item = localStorage.getItem(key);
  // `JSON.parse` arroja error al evaluar `'undefined'`
  if (item === 'undefined') {
    return undefined;
  }
  try {
    return JSON.parse(item);
  } catch (cause) {
    return item;
  }
};
export var setItem = function setItem(key, value) {
  return localStorage.setItem(key, value);
};
export var removeItem = function removeItem(key) {
  return localStorage.removeItem(key);
};
var PersistentStore = function PersistentStore() {
  return {
    clear: function clear() {
      return localStorage.clear();
    },
    getSession: function getSession() {
      return getItem(keys.SESSION);
    },
    setSession: function setSession(session) {
      return setItem(keys.SESSION, session);
    },
    getRut: function getRut() {
      return getItem(keys.RUT);
    },
    setRut: function setRut(rut) {
      return setItem(keys.RUT, rut);
    },
    clearRut: function clearRut() {
      return removeItem(keys.RUT);
    },
    setName: function setName(nombre) {
      return setItem(keys.NOMBRE, nombre);
    },
    clearName: function clearName() {
      return removeItem(keys.NOMBRE);
    },
    setCorreo: function setCorreo(correo) {
      return setItem(keys.CORREO, correo);
    },
    clearCorreo: function clearCorreo() {
      return removeItem(keys.CORREO);
    },
    setTlf: function setTlf(tlf) {
      return setItem(keys.TELEFONO, tlf);
    },
    clearTlf: function clearTlf() {
      return removeItem(keys.TELEFONO);
    },
    setEsPrime: function setEsPrime(prime) {
      return setItem(keys.PRIME, prime);
    },
    clearEsPrime: function clearEsPrime() {
      return removeItem(keys.PRIME);
    },
    getSecurityApiToken: function getSecurityApiToken() {
      return getItem(keys.SECURITY_API_TOKEN);
    },
    setSecurityApiToken: function setSecurityApiToken(token) {
      return setItem(keys.SECURITY_API_TOKEN, token);
    },
    getLogApiToken: function getLogApiToken() {
      return getItem(keys.LOG_API_TOKEN);
    },
    setLogApiToken: function setLogApiToken(token) {
      return setItem(keys.LOG_API_TOKEN, token);
    },
    getLogApiRefreshToken: function getLogApiRefreshToken() {
      return getItem(keys.LOG_API_REFRESH_TOKEN);
    },
    setLogApiRefreshToken: function setLogApiRefreshToken(token) {
      return setItem(keys.LOG_API_REFRESH_TOKEN, token);
    },
    getLoyaltyApiToken: function getLoyaltyApiToken() {
      return getItem(keys.LOYALTY_API_TOKEN);
    },
    setLoyaltyApiToken: function setLoyaltyApiToken(token) {
      return setItem(keys.LOYALTY_API_TOKEN, token);
    },
    getEcommerceApiToken: function getEcommerceApiToken() {
      return getItem(keys.ECOMMERCE_API_TOKEN);
    },
    setEcommerceApiToken: function setEcommerceApiToken(token) {
      return setItem(keys.ECOMMERCE_API_TOKEN, token);
    },
    getEcommerceApiRefreshToken: function getEcommerceApiRefreshToken() {
      return getItem(keys.ECOMMERCE_API_REFRESH_TOKEN);
    },
    setEcommerceApiRefreshToken: function setEcommerceApiRefreshToken(token) {
      return setItem(keys.ECOMMERCE_API_REFRESH_TOKEN, token);
    },
    getLoyaltyApiRefreshToken: function getLoyaltyApiRefreshToken() {
      return getItem(keys.LOYALTY_API_REFRESH_TOKEN);
    },
    setLoyaltyApiRefreshToken: function setLoyaltyApiRefreshToken(token) {
      return setItem(keys.LOYALTY_API_REFRESH_TOKEN, token);
    },
    getSecurityApiRefreshToken: function getSecurityApiRefreshToken() {
      return getItem(keys.SECURITY_API_REFRESH_TOKEN);
    },
    setSecurityApiRefreshToken: function setSecurityApiRefreshToken(token) {
      return setItem(keys.SECURITY_API_REFRESH_TOKEN, token);
    },
    /**
     * @param {string} id
     * @returns {number} Tiempo de inicio en ms
     */
    getAsyncTransactionStartTime: function getAsyncTransactionStartTime(numeroRut) {
      return getItem(keys.ASYNC_TRANSACTIONS)[numeroRut].tiempoInicio;
    },
    getPermision: function getPermision(numeroRut) {
      var item = getItem(keys.ASYNC_TRANSACTIONS);
      return item ? item[numeroRut] ? item[numeroRut].loadingPermision : false : false;
    },
    removePermision: function removePermision(rut) {
      var item = getItem(keys.ASYNC_TRANSACTIONS);
      if (item) {
        item[rut].loadingPermision = false;
      }
      setItem(keys.ASYNC_TRANSACTIONS, JSON.stringify(item));
    },
    removeLoadingPermisions: function removeLoadingPermisions() {
      setItem(keys.ASYNC_TRANSACTIONS, null);
    },
    /**
     * @param {string} id
     */
    setAsyncTransactionStartTime: function setAsyncTransactionStartTime(id) {
      var key = keys.ASYNC_TRANSACTIONS;
      var date = new Date();
      date.setHours(date.getHours(), date.getMinutes() + 2, date.getSeconds(), date.getMilliseconds());
      setItem(key, JSON.stringify(_objectSpread(_objectSpread({}, getItem(key)), {}, _defineProperty({}, id, {
        tiempoInicio: date.getTime(),
        loadingPermision: true
      }))));
    },
    getTransacciones: function getTransacciones() {
      return getItem(keys.TRANSACCIONES);
    },
    setTransacciones: function setTransacciones(transacciones) {
      return setItem(keys.TRANSACCIONES, JSON.stringify(transacciones));
    },
    clearTransacciones: function clearTransacciones() {
      return removeItem(keys.TRANSACCIONES);
    },
    clearChatCenter: function clearChatCenter() {
      removeItem(keys.BOT_CONTEXT);
      removeItem(keys.INFO_CONV);
      removeItem(keys.INFO_CONV_ESPERA);
      sessionStorage.removeItem(keys.CONFIG_CC);
    },
    getAdditionalId: function getAdditionalId() {
      return getItem(keys.ADDITIONAL_ID);
    },
    setAdditionalId: function setAdditionalId(id) {
      return setItem(keys.ADDITIONAL_ID, id);
    },
    clearAdditionalId: function clearAdditionalId() {
      return removeItem(keys.ADDITIONAL_ID);
    },
    getAdditionalName: function getAdditionalName() {
      return getItem(keys.ADDITIONAL_NAME);
    },
    setAdditionalName: function setAdditionalName(name) {
      return setItem(keys.ADDITIONAL_NAME, name);
    },
    clearAdditionalName: function clearAdditionalName() {
      return removeItem(keys.ADDITIONAL_NAME);
    }
  };
};
export default PersistentStore();